import { Box } from "@mui/material"
import Text from "./Text"

interface TextParagraphProps {
    heading?: string
    subheading?: string
    body?: string | null
}

const TextParagraph = ({ heading, subheading, body }: TextParagraphProps) => {
    return (
        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start", gap: "10px" }}>
            {heading && (
                <Text bold size={"heading"}>
                    {heading}
                </Text>
            )}
            {subheading && (
                <Text bold size={"standard"}>
                    {subheading}
                </Text>
            )}
            {body && <Text size={"standard"}>{body}</Text>}
        </Box>
    )
}

export default TextParagraph
