import { Navigate, Route, Routes } from "react-router-dom"
import { Grid } from "@mui/material"
import { ROUTES } from "../utils/api"
import WelcomePage from "../pages/Home/Welcome"
import Library from "../pages/Library/Library"
import Settings from "../pages/Settings"
import ActivateAccount from "../pages/ActivateAccount"
import Admin from "../pages/Admin"
import About from "../pages/About"
import Loading from "../pages/Loading"
import Navbar from "./Navbar"
import { useCurrentUser } from "../hooks/contexts/currentUserContext"
import { useMqttIsConnected } from "../mqtt/mqttHandlerContext"
import { useIsSmallScreen } from "../hooks/useIsSmallScreen"
import MobileHeader from "./Header"
import LogIn from "../pages/LogIn"
import RequestPasswordReset from "../pages/RequestPasswordReset"
import ResetPassword from "../pages/ResetPassword"

const ContentsLoggedIn = () => {
    const currentUser = useCurrentUser()
    const mqttIsConnected = useMqttIsConnected()
    const isSmallScreen = useIsSmallScreen()

    return (
        <>
            {/**
             * Show contents if one of the following cases are true:
             * 1) A current user exists, a customer is connected to the user and MQTT is connected.
             * 2) A current user exists, but there is no customer connected to the user.
             *
             * If none of the cases above are true, show the loading page.
             * */}
            {currentUser && ((currentUser.customer && mqttIsConnected) || !currentUser.customer) ? (
                <Grid container sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
                    <Grid item xs sx={{ overflow: "auto" }}>
                        {/* {!isSmallScreen && (
                            <Grid item xs="auto">
                                <Navbar />
                            </Grid>
                        )} */}
                        {isSmallScreen && <MobileHeader />}
                        <Routes>
                            <Route path={ROUTES.HOME} element={currentUser?.customer ? <WelcomePage /> : <Navigate to={ROUTES.ACTIVATE_ACCOUNT} replace />} />
                            <Route path={ROUTES.LIBRARY.MAIN + "/*"} element={currentUser?.customer ? <Library /> : <Navigate to={ROUTES.ACTIVATE_ACCOUNT} replace />} />
                            <Route path={ROUTES.SETTINGS.MAIN + "/*"} element={<Settings />} />
                            <Route path={ROUTES.ACTIVATE_ACCOUNT} element={currentUser?.customer ? <Navigate to={ROUTES.HOME} replace /> : <ActivateAccount />} />
                            <Route path={ROUTES.LOG_IN} element={<Navigate to={ROUTES.HOME} replace />} />
                            <Route path={ROUTES.REQUEST_PASSWORD_RESET} element={currentUser?.customer ? <Navigate to={ROUTES.HOME} replace /> : <RequestPasswordReset />} />
                            <Route path={ROUTES.RESET_PASSWORD} element={currentUser?.customer ? <Navigate to={ROUTES.HOME} replace /> : <ResetPassword />} />
                            <Route path={ROUTES.SIGN_UP} element={<Navigate to={ROUTES.HOME} replace />} />
                            <Route path={ROUTES.ABOUT} element={<About />} />

                            <Route path="*" element={currentUser?.customer ? <WelcomePage /> : <Navigate to={ROUTES.ACTIVATE_ACCOUNT} replace={true} />} />

                            {currentUser?.admin && <Route path={ROUTES.ADMIN.MAIN + "/*"} element={<Admin />} />}
                        </Routes>
                    </Grid>
                    {isSmallScreen && (
                        <Grid item xs="auto">
                            <Navbar />
                        </Grid>
                    )}
                </Grid>
            ) : (
                <Loading />
            )}
        </>
    )
}

export default ContentsLoggedIn
