import { Box, SxProps, Theme } from "@mui/material";
import React, { useState } from "react";

import "./Image.css";

type ImageProps = {
  src: string;
  color?: string;
  description: string;
  sx?: SxProps<Theme>;
};

export const Image = ({
  src,
  color = "#cfcfcf",
  description,
  sx,
}: ImageProps) => {
  const [isLoaded, setLoaded] = useState<boolean>(false);

  return (
    <Box className="image" sx={{ backgroundColor: color, ...sx }}>
      <img
        src={src}
        alt={description}
        className={`real real${isLoaded ? "-visible" : "-hidden"}`}
        onLoad={() => setLoaded(true)}
      />
    </Box>
  );
};
