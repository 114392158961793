export const MQTT_API = {
    CREDITS: {
        //TODO: change to unique and set up on HiveMQ
        USERNAME: "APP-jkcAzVMAqqCRdkTXzYngD",
        PASSWORD: "mqtt-UjGa_ytiY2",
    },

    INSTRUMENTS: {
        /**
         * These are the newly developed instruments that later will be used in pilottesting in Stockholm
         * These are 'connected' to app.funki.se
         */

        //TODO: Remove
        BUTTONS: "/buttons1",
        JOYSTICKS: "/joysticks1",
        JOYSTICKS2: "/joysticks2",
        LEVERS: "/levers1",
        TOUCH: "/touch1",
        ALL: "/all1",

        /**
         * These below is used in Kulturskolan pilottesting.
         * These are 'connected' to kulturskolan.funki.se
         */
        // BUTTONS: "/buttons",
        // JOYSTICKS: "/joysticks",
        // LEVERS: "/levers",
        // TOUCH: "/touch",
        //ALL: "/all"
    },

    TOPIC_ACTION: {
        HEARTBEAT: `heartbeat`,
        ON_START: `onStart`,
        VOLUME: `changeVolume`,
        SONG: `changeSong`,
        BACKINGTRACK: `backingtrack`,
        METRONOME: `metronome`,
        SECTION: `changeSection`,
        PLAY_PAUSE: `playPause`,
        MASTER_VOLUME: `masterVolume`,
        RESET_NETWORK: "resetNetwork",
        SET_PARAMETERS: "setParameters",
    },
}

export const ROUTES = {
    HOME: "/",
    LIBRARY: {
        MAIN: "/library",
        MUSIC: {
            fullPath: "/library/music",
            subPath: "/music",
        },
        SOUNDBANKS: {
            fullPath: "/library/soundbanks",
            subPath: "/soundbanks",
        },
        KEY: {
            fullPath: "/library/key",
            subPath: "/key",
        },
    },
    SETTINGS: {
        MAIN: "/settings",
        ACCOUNT: {
            fullPath: "/settings/account",
            subPath: "/account",
        },
        LICENSE: {
            fullPath: "/settings/license",
            subPath: "/license",
        },
        ABOUT: {
            fullPath: "/settings/about",
            subPath: "/about",
        },
    },
    ABOUT: "/about",
    SIGN_UP: "/sign-up",
    LOG_IN: "/log-in",
    REQUEST_PASSWORD_RESET: "/request-password-reset",
    RESET_PASSWORD: "/reset-password",
    AUTH_ACTION: "/auth/action",
    ACTIVATE_ACCOUNT: "/activate-account",
    ADMIN: {
        MAIN: "/admin",
        CREATE_CUSTOMER: {
            fullPath: "/admin/create-customer",
            subPath: "/create-customer",
        },
        CUSTOMERS: {
            fullPath: "/admin/customers",
            subPath: "/customers",
        },
        SOUNDBANKS_AND_SONGS: {
            fullPath: "/admin/soundbanks-and-songs",
            subPath: "/soundbanks-and-songs",
        },
        USERS: {
            fullPath: "/admin/users",
            subPath: "/users",
        },
    },
}

export const touchSounds = [
    // { id: "synth-easy", name: "Synth Duo" },
    // { id: "bell-easy", name: "Bell Duo" },
    { id: "gong-hard", name: "Gong" },
    { id: "synth-hard", name: "Synth" },
    { id: "bell-hard", name: "Bell" },
]

export const buttonSounds = [
    { id: "bright-bell", name: "Klocka, kort" },
    { id: "piano", name: "Piano" },
    { id: "gitarr", name: "Gitarr" },
    { id: "synth", name: "Synth" },
    { id: "bell", name: "Klocka, lång" },
]

export const joystickSounds = [
    { id: "piano", name: "Piano" },
    { id: "bell", name: "Klocka, lång" },
    // { id: "gitarr", name: "Gitarr" },
    { id: "synth", name: "Synth" },
    { id: "bright-bell", name: "Klocka, kort" },
]
