import React from "react"
import { useState, useEffect } from "react"
import { usePostMqttChangeSong } from "../../../mqtt/mqttHandlerContext"
import { Instrument, Song, SONG_TYPES } from "../../../utils/types"
import { Box, ToggleButton, ToggleButtonGroup } from "@mui/material"
import Text from "../../../assets/components/Text"
import { formatString } from "../../../utils/formatter"
import { buttonSounds, joystickSounds, touchSounds } from "../../../utils/api"
import { useConnectedInstruments } from "../../../hooks/contexts/connectedInstrumentsContext"
import { useChangeCurrentSong } from "../../../hooks/contexts/currentSongContext"
import { useIsSmallScreen } from "../../../hooks/useIsSmallScreen"
import { toggleButtonGroupStyling, toggleButtonStyling } from "../../../utils/sharedStyling"

const InstrumentSettings = ({ currentSong, selectedInstrument }: { currentSong: Song | undefined; selectedInstrument: Instrument }) => {
    const changeCurrentSong = useChangeCurrentSong()
    const postChangeCong = usePostMqttChangeSong()
    const [soundAlignment, setSoundAlignment] = React.useState<string | null>("1")
    const [sound, setSound] = useState<string>("synth")
    const [value, setValue] = useState<string>("3")
    const [hasUserInteracted, setHasUserInteracted] = useState<boolean>(false)
    const instrumentId = selectedInstrument.deviceId
    const instrumentType = selectedInstrument.type

    const connectedInstruments = useConnectedInstruments()

    const handleSoundAlignment = (event: React.MouseEvent<HTMLElement>, newAlignment: string | null) => {
        setSoundAlignment(newAlignment)
        setHasUserInteracted(true)
    }

    const [instrumentSound, setInstrumentSound] = useState<{
        [key: string]: string
    }>({})

    const handleChangeSound = ({ sound }: { sound: string }) => {
        if (!instrumentId) {
            console.error("Instrument ID is undefined")
            return
        }
        setInstrumentSound((prevVolumes) => ({
            ...prevVolumes,
            [instrumentId]: sound,
        }))
        setSound(sound)
        setHasUserInteracted(true)
    }

    const handleChangeValue = ({ value }: { value: string }) => {
        setValue(value)
        setHasUserInteracted(true)
    }

    useEffect(() => {
        if (hasUserInteracted) {
            changeCurrentSong({ songType: SONG_TYPES.KEY, instrumentSound: sound, deviceId: instrumentId })
        }
    }, [sound, value, hasUserInteracted])

    // Fetch initial settings values from storage when the component mounts
    useEffect(() => {
        const storedSoundString = localStorage.getItem("instrumentSound")
        if (storedSoundString) {
            const storedSound = JSON.parse(storedSoundString)
            setSound(storedSound)
        }
    }, [])

    // Save setting values to storage whenever they change
    useEffect(() => {
        localStorage.setItem("instrumentSound", JSON.stringify(instrumentSound))
    }, [instrumentSound])

    return (
        <Box>
            {instrumentType === "touch" ? (
                <ToggleButtonGroup value={instrumentSound[instrumentId] ?? ""} exclusive fullWidth onChange={handleSoundAlignment} aria-label="text alignment" sx={toggleButtonGroupStyling}>
                    {touchSounds.map((sound) => (
                        <ToggleButton key={sound.id} value={sound.id} aria-label={sound.name} onClick={() => handleChangeSound({ sound: sound.id })} sx={toggleButtonStyling}>
                            {formatString(sound.name)}
                        </ToggleButton>
                    ))}
                </ToggleButtonGroup>
            ) : instrumentType === "buttons" ? (
                <ToggleButtonGroup value={instrumentSound[instrumentId] ?? ""} exclusive fullWidth onChange={handleSoundAlignment} aria-label="text alignment" sx={toggleButtonGroupStyling}>
                    {buttonSounds.map((sound) => (
                        <ToggleButton key={sound.id} value={sound.id} aria-label={sound.name} onClick={() => handleChangeSound({ sound: sound.id })} sx={toggleButtonStyling}>
                            {formatString(sound.name)}
                        </ToggleButton>
                    ))}
                </ToggleButtonGroup>
            ) : (
                <ToggleButtonGroup value={instrumentSound[instrumentId] ?? ""} exclusive fullWidth onChange={handleSoundAlignment} aria-label="text alignment" sx={toggleButtonGroupStyling}>
                    {joystickSounds.map((sound) => (
                        <ToggleButton key={sound.id} value={sound.id} aria-label={sound.name} onClick={() => handleChangeSound({ sound: sound.id })} sx={toggleButtonStyling}>
                            {formatString(sound.name)}
                        </ToggleButton>
                    ))}
                </ToggleButtonGroup>
            )}
        </Box>
    )
}

export default InstrumentSettings
