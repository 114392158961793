import { Alert, Grid, Stack } from "@mui/material"
import { DataGrid, GridActionsCellItem, GridColDef, GridEventListener, GridRowEditStopReasons, GridRowId, GridRowModel, GridRowModes, GridRowModesModel } from '@mui/x-data-grid'
import TextParagraph from "../../../assets/components/TextParagraph"
import { deleteCustomer, getAllCustomerData, updateCustomerData } from "../../../firebase/databaseApi"
import { Customer, Instrument } from "../../../utils/types"
import { useEffect, useState, useCallback } from "react"
import { CancelOutlined, ContentCopyOutlined, DeleteOutline, EditOutlined, PianoOutlined, QueueMusicOutlined, SaveOutlined, VideogameAssetOutlined } from "@mui/icons-material"
import { FirebaseError } from "firebase/app"
import CustomersToolbar from "./CustomersToolbar"
import EditSongPackagesDialog from "./EditSongPackagesDialog"
import EditInstrumentsDialog from "./EditInstrumentsDialog"

interface Row extends Customer {
  isNew?: boolean
  [key: string]: any;
}

type AlertColor = "error" | "warning" | "info" | "success"

const initialAlert = {
  severity: "",
  text: "",
}

type ActivationCodeCopyCellProp = {
  code: string
}

const ActivationCodeCopyCell = ({code}: ActivationCodeCopyCellProp) => {
  return (
    <></>
  )
}

type InstrumentListCellProp = {
  instruments: Instrument[]
}

const InstrumentListCell = ({instruments}: InstrumentListCellProp) => {
  return (
    <Stack sx={{ py: "10px" }}>
      {instruments && instruments.map((instrument) => (
        <div key={instrument.deviceId}>
          <VideogameAssetOutlined sx={{color: "#BFBFBF"}} /> {instrument.type} ({instrument.deviceId})
        </div>
      ))}
    </Stack>
  )
}

type SongPackageListCellProp = {
  songPackages: string[]
}

const SongPackageListCell = ({songPackages}: SongPackageListCellProp) => {
  return (
    <Stack sx={{ py: "10px" }}>
      {songPackages && songPackages.map((songPackage) => (
        <div key={songPackage}>
          {songPackage}
        </div>
      ))}
    </Stack>
  )
}

const prepareCustomerForDatabase = (newRow: Row) => {
  const customerData: Partial<Customer> = {}
  Object.keys(newRow).forEach((key) => {
    // Replace all undefined and "" (empty string) values with null
    if (newRow[key] === undefined || newRow[key] === "") {
      customerData[key] = null
    } else {
      customerData[key] = newRow[key]
    }
  })

  // Remove isNew prop
  delete customerData.isNew

  return customerData
}

const CustomersTable = () => {
    const [alert, setAlert] = useState(initialAlert)
    const [rows, setRows] = useState<Row[]>([]);
    const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
    const [editSongPackagesDialogOpen, setEditSongPackagesDialogOpen] = useState<boolean>(false)
    const [editInstrumentsDialogOpen, setEditInstrumentsDialogOpen] = useState<boolean>(false)
    const [customerIdToEdit, setCustomerIdToEdit] = useState<string | null>(null)

    ////// USE EFFECTS //////

    useEffect(() => {
      fetchCustomers()
    }, [])

    ////// FUNCTIONS //////

    const fetchCustomers = async () => {
        const customers: Customer[] = await getAllCustomerData()
        setRows(customers)
    }

    const openEditSongPackagesDialog = () => {
      setEditSongPackagesDialogOpen(true)
    }

    const closeEditSongPackagesDialog = () => {
      setEditSongPackagesDialogOpen(false)
    }

    const openEditInstrumentsDialog = () => {
      setEditInstrumentsDialogOpen(true)
    }

    const closeEditInstrumentsDialog = () => {
      setEditInstrumentsDialogOpen(false)
    }


    const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
        if (params.reason === GridRowEditStopReasons.rowFocusOut) {
          event.defaultMuiPrevented = true;
        }
    };

    const handleEditClick = (id: GridRowId) => () => {
      setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    };

    const handleCopyActivationCodeClick = (id: GridRowId) => () => {
      navigator.clipboard.writeText(id as string);
    };

    const handleEditSongPackagesClick = (id: GridRowId) => () => {
      // Set customer id to edit
      setCustomerIdToEdit(id as string)

      // Open dialog
      openEditSongPackagesDialog()
    }

    const handleEditInstrumentsClick = (id: GridRowId) => () => {
      // Set customer id to edit
      setCustomerIdToEdit(id as string)

      // Open dialog
      openEditInstrumentsDialog()
    }

    const handleSaveClick = (id: GridRowId) => () => {
      setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    };

    const handleDeleteClick = (id: GridRowId) => async () => {
      // Get the row with this id
      const rowToDelete = rows.find((row) => row.id === id);

      try {
        // Sanity check that a row was found
        if (!rowToDelete) {
          throw Error(`Could not find any row with id ${id}`)
        }

        // Delete customer in database
        await deleteCustomer(id as string)
        
        // Feedback success to user
        setAlert({severity: "success", text: `Tog bort ${rowToDelete?.alias} (${id})`})
      } catch (error: any) {
        // Abort delete and feedback user
        setAlert({severity: "error", text: `Misslyckades att ta bort ${rowToDelete?.alias} (${id}). Error-meddelande: ${error.message}`})
        return
      }

      // Update table visually
      setRows(rows.filter((row) => row.id !== id));
    };

    const handleCancelClick = (id: GridRowId) => async () => {
      // Update table visually
      setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
      });

      const editedRow = rows.find((row) => row.id === id);
      if (editedRow!.isNew) {
        setRows(rows.filter((row) => row.id !== id));
      }
    };

    const updateCustomer = async (newRow: Row, oldRow: Row) => {
      // Prepare data for database
      const customerData: Customer = prepareCustomerForDatabase(newRow) as Customer

      // Update change in database
      await updateCustomerData(customerData, newRow.isNew)
    }

    const processRowUpdate = async (newRow: GridRowModel, originalRow: GridRowModel) => {
      // Reset alert
      setAlert(initialAlert)

      // Update customer in database
      await updateCustomer(newRow as Row, originalRow as Row)

      // Report success
      if (newRow.isNew) {
        setAlert({ severity: "success", text: `Lade till ${newRow.alias} (${newRow.id})` })
      } else {
        setAlert({ severity: "success", text: `Uppdaterade ${newRow.alias} (${newRow.id})` })
      }

      // Update rows visually
      const updatedRow: Partial<Row> = { ...newRow, isNew: false };
      setRows(rows.map((row) => (
        (row.id === newRow.id || row.id === originalRow.id) ? 
        {...row, ...updatedRow} 
        : row
      )));
      return updatedRow;
    };

    const handleProcessRowUpdateError = useCallback((error: Error) => {
      if (error instanceof FirebaseError) {
        // Firebase error

        // Feedback user
        setAlert({ severity: "error", text: `Misslyckades att uppdatera kund. Error-meddelande: ${error.message}` })
      } else {
        // Other error

        // Feedback user
        setAlert({ severity: "error", text: `Misslyckades att uppdatera kund. Error-meddelande: ${error.message}` })
      }
    }, []);

    const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
      setRowModesModel(newRowModesModel);
    };

    ////// TABLE COLUMN DEFS //////

    const columns: GridColDef[] = [
      { 
        field: 'copy',
        type: 'actions', 
        headerName: '',
        width: 50,
        cellClassName: 'actions',
        getActions: ({ id }) => {  
          return [
            <GridActionsCellItem
              icon={<ContentCopyOutlined />}
              label="Copy activation code"
              title="Kopiera aktiveringskod"
              className="textPrimary"
              onClick={handleCopyActivationCodeClick(id)}
              color="inherit"
            />,
          ];
        },
      },
      { field: 'id', headerName: 'Id', flex: 0.5, minWidth: 70, maxWidth: 230, editable: false },
      { field: 'alias', headerName: 'Alias', flex: 1, minWidth: 100, editable: true },
      { field: 'contactEmail', headerName: 'Email för kontakt', flex: 1, maxWidth: 130, editable: true },
      { 
        field: 'paymentPlan', 
        headerName: 'Betalplan', 
        flex: 1,
        maxWidth: 130,
        editable: true,
        type: 'singleSelect',
        valueOptions: [
          {value: 'one-month', label: "1 mån"}, 
          {value: 'three-month', label: "3 mån"},
          {value: 'one-year', label: "1 år"},
        ],
      },
      { 
        field: 'paymentMethod', 
        headerName: 'Betalmetod', 
        flex: 1,
        maxWidth: 130,
        editable: true,
        type: 'singleSelect',
        valueOptions: [
          {value: 'direct-debit', label: "Autogiro"}, 
          {value: 'invoice', label: "Faktura"},
        ],
      },
      { 
        field: 'licenseStartDate', 
        headerName: 'Licensstart', 
        type: 'date', 
        valueFormatter: (params) => (
            params.value ? 
            new Date(params.value).toLocaleDateString('sv-SE') 
            : null
          ),
        flex: 1, 
        maxWidth: 130, 
        editable: true 
      },
      { 
        field: 'licenseExpDate', 
        headerName: 'Licensutgång', 
        type: 'date', 
        valueFormatter: (params) => (
            params.value ? 
            new Date(params.value).toLocaleDateString('sv-SE') 
            : null
          ),
        flex: 1, 
        maxWidth: 130, 
        editable: true 
      },
      { 
        field: 'instruments', 
        headerName: 'Instrument', 
        renderCell: (params) => <InstrumentListCell instruments={params.value} />,
        flex: 1.4, 
        minWidth: 130, 
        editable: false 
      },
      { 
        field: 'songPackages', 
        headerName: 'Låtpaket', 
        renderCell: (params) => <SongPackageListCell songPackages={params.value} />,
        flex: 1, 
        maxWidth: 230, 
        editable: false 
      },
      {
        field: 'actions',
        type: 'actions',
        headerName: 'Actions',
        flex: 1, 
        minWidth: 150,
        cellClassName: 'actions',
        getActions: ({ id }) => {
          const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
  
          if (isInEditMode) {
            return [
              <GridActionsCellItem
                icon={<SaveOutlined />}
                label="Save"
                title="Spara"
                sx={{
                  color: 'primary.main',  
                }}
                onClick={handleSaveClick(id)}
              />,
              <GridActionsCellItem
                icon={<CancelOutlined />}
                label="Cancel"
                title="Avbryt"
                className="textPrimary"
                onClick={handleCancelClick(id)}
                color="inherit"
              />,
              <GridActionsCellItem
                icon={<QueueMusicOutlined />}
                label="Edit song packages"
                title="Redigera låtpaket"
                className="textPrimary"
                onClick={handleEditSongPackagesClick(id)}
                color="inherit"
                disabled
              />,
              <GridActionsCellItem
                icon={<DeleteOutline />}
                label="Delete"
                title="Radera"
                onClick={handleDeleteClick(id)}
                color="error"
                disabled
              />,
            ];
          }
  
          return [
            <GridActionsCellItem
              icon={<EditOutlined />}
              label="Edit"
              title="Redigera"
              className="textPrimary"
              onClick={handleEditClick(id)}
              color="inherit"
            />,
            <GridActionsCellItem
              icon={<PianoOutlined />}
              label="Edit instruments"
              title="Redigera instrument"
              className="textPrimary"
              onClick={handleEditInstrumentsClick(id)}
              color="inherit"
            />,
            <GridActionsCellItem
              icon={<QueueMusicOutlined />}
              label="Edit song packages"
              title="Redigera låtpaket"
              className="textPrimary"
              onClick={handleEditSongPackagesClick(id)}
              color="inherit"
            />,
            <GridActionsCellItem
              icon={<DeleteOutline />}
              label="Delete"
              title="Radera"
              onClick={handleDeleteClick(id)}
              color="error"
            />,
          ];
        },
      },
    ];

    return (
      <Stack sx={{ height: "100%", width: "100%", display: "flex", flexDirection: "column" }}>
        <Grid item xs="auto">
          <TextParagraph heading="Kunder" />
        </Grid>

        <Grid item xs="auto">
          {alert.text ? (
            <Alert
                severity={alert.severity as AlertColor}
                onClose={() => {
                    setAlert(initialAlert)
                }}
            >
                {alert.text}
            </Alert>
            ) : null}
        </Grid>
          
        <Grid item xs sx={{overflow: "auto"}}>
          <DataGrid
            sx={{
              '& .css-t89xny-MuiDataGrid-columnHeaderTitle': {
                fontFamily: "Raleway",
                fontWeight: "bold",
              },
              "& .MuiDataGrid-cellContent": {
                minHeight: "52px"
              } 
            }}
            rows={rows}
            columns={columns}
            getRowHeight={() => 'auto'}
            getEstimatedRowHeight={() => 100}
            editMode="row"
            initialState={{
                pagination: {
                    paginationModel: { page: 0, pageSize: 100 },
                },
            }}
            pageSizeOptions={[10, 30, 50, 100]}
            rowModesModel={rowModesModel}
            onRowModesModelChange={handleRowModesModelChange}
            onRowEditStop={handleRowEditStop}
            processRowUpdate={processRowUpdate}
            onProcessRowUpdateError={handleProcessRowUpdateError}
            slots={{
                toolbar: CustomersToolbar,
            }}
            slotProps={{
                toolbar: { setRows, setRowModesModel, rows },
            }}
          />
        </Grid>

        <Grid item>
          <EditSongPackagesDialog 
            open={editSongPackagesDialogOpen}
            closeDialog={closeEditSongPackagesDialog}
            setRows={setRows} rows={rows}
            customerId={customerIdToEdit}
          />

          <EditInstrumentsDialog 
            open={editInstrumentsDialogOpen}
            closeDialog={closeEditInstrumentsDialog}
            setRows={setRows} rows={rows}
            customerId={customerIdToEdit}
          />
        </Grid>
      </Stack>
    )
}

export default CustomersTable
