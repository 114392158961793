import logo from "../funki_VAG_1280_red.png"

type LogoSize = 's' | 'm' | 'l'

type LogoProps = {
    size?: LogoSize
}

const LogoSizes: Record<LogoSize, number> = {
    's': 30,
    'm': 45,
    'l': 60
}

export const Logo = ( { size = 'm' } : LogoProps) => {

    return <img src={logo} alt="Logo" style={{ height: LogoSizes[size] }} />
}