import {
    EmailAuthProvider,
    User,
    checkActionCode,
    confirmPasswordReset,
    createUserWithEmailAndPassword,
    reauthenticateWithCredential,
    sendPasswordResetEmail,
    signInWithEmailAndPassword,
    signOut,
    updatePassword,
    updateProfile,
} from "firebase/auth"
import { auth } from "./firebaseConfig"
import { addNewUser } from "./databaseApi"

export const signUp = async (name: string | null | undefined, email: string, password: string) => {
    try {
        const userCredential = await createUserWithEmailAndPassword(auth, email, password)
        console.log("User signed up successfully!")

        if (name) {
            // Set user display name
            await updateProfile(userCredential.user, { displayName: name })
        }

        // Create user entity in database
        addNewUser(
            userCredential.user.uid, 
            email, name || null, 
            userCredential.user.metadata.creationTime || null
        )
    } catch (error) {
        console.log("ERROR: User failed to sign up!", error)
        // TODO: remove user created in auth (if the user was created in auth
        // but saving user to database failed)
        throw error
    }
}

export const logIn = async (email: string, password: string) => {
    try {
        await signInWithEmailAndPassword(auth, email, password)
        console.log("User logged in successfully!")
    } catch (error) {
        console.log("ERROR: User failed to log in!")
        throw error
    }
}

export const logOut = async () => {
    try {
        await signOut(auth)
        console.log("User logged out successfully!")
    } catch (error) {
        console.log("ERROR: User failed to log out!")
        throw error
    }
}

export const reauthenticateUser = async (user: User, password: string) => {
    try {
        // Create auth credential
        const authCred = EmailAuthProvider.credential(user.email as string, password)

        return await reauthenticateWithCredential(user, authCred)
    } catch (error) {
        throw error
    }
}

export const changePassword = async (user: User, curPassword: string, newPassword: string) => {
    try {
        // First, reauthenticate user
        const userCredential = await reauthenticateUser(user, curPassword)

        // Update password
        return await updatePassword(userCredential.user, newPassword)
    } catch (error) {
        throw error
    }
}

export const startPasswordReset = async (email: string) => {
    try {
        await sendPasswordResetEmail(auth, email)
        console.log("Sent password reset email to", email)
    } catch (error) {
        console.log("ERROR: Failed to send password reset email to", email)
        throw error
    }
}

export const checkOobCode = async (oobCode: string) => {
    try {
        await checkActionCode(auth, oobCode)
    } catch (error) {
        throw error
    }
}

export const completePasswordReset = async (code: string, newPassword: string) => {
    try {
        await confirmPasswordReset(auth, code, newPassword)
        console.log("Password reset is completed")
    } catch (error) {
        console.log("ERROR: Failed to complete password reset")
        throw error
    }
}
