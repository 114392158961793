import { useLocation, useNavigate } from "react-router-dom"
import { ROUTES } from "../utils/api"
import Loading from "../pages/Loading"
import { useEffect } from "react"

const AuthAction = () => {
    const navigate = useNavigate()
    const queryParams = new URLSearchParams(useLocation().search)
    const mode = queryParams.get("mode")
    const oobCode = queryParams.get("oobCode")

    useEffect(() => {
        switch (mode) {
            case "resetPassword":
                const newQuery = `?oobCode=${oobCode}`

                // Redirect to corresponding page
                navigate({
                    pathname: ROUTES.RESET_PASSWORD,
                    search: newQuery,
                })
                break
            // More cases for other auth actions can be added here later
            default:
                break
        }
    }, [mode, oobCode, navigate])

    return <Loading />
}

export default AuthAction
