import { Navigate, Route, Routes } from "react-router-dom"
import { Grid } from "@mui/material"
import { ROUTES } from "../utils/api"
import SignUp from "../pages/SignUp"
import LogIn from "../pages/LogIn"
import RequestPasswordReset from "../pages/RequestPasswordReset"
import ResetPassword from "../pages/ResetPassword"
import AuthAction from "./AuthAction"
import About from "../pages/About"

const ContentsNoUser = () => {
    return (
        <Grid container sx={{ display: "flex", flexDirection: "column", height: "100vh" }}>
            <Grid item xs sx={{ overflow: "auto" }}>
                <Routes>
                    <Route path={ROUTES.HOME} element={<Navigate to={ROUTES.LOG_IN} replace />} />
                    <Route path={ROUTES.SETTINGS.MAIN + "/*"} element={<Navigate to={ROUTES.LOG_IN} replace />} />
                    <Route path={ROUTES.LOG_IN} element={<LogIn />} />
                    <Route path={ROUTES.SIGN_UP} element={<SignUp />} />
                    <Route path={ROUTES.REQUEST_PASSWORD_RESET} element={<RequestPasswordReset />} />
                    <Route path={ROUTES.RESET_PASSWORD} element={<ResetPassword />} />
                    <Route path={ROUTES.AUTH_ACTION} element={<AuthAction />} />
                    <Route path={ROUTES.ABOUT} element={<About />} />
                    <Route path="*" element={<Navigate to={ROUTES.LOG_IN} replace />} />
                </Routes>
            </Grid>
        </Grid>
    )
}

export default ContentsNoUser
