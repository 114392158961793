import { Alert, Grid, Stack } from "@mui/material"
import { DataGrid, GridActionsCellItem, GridColDef, GridEventListener, GridRowEditStopReasons, GridRowId, GridRowModel, GridRowModes, GridRowModesModel } from '@mui/x-data-grid'
import TextParagraph from "../../../assets/components/TextParagraph"
import { getAllUserData, getCustomerAlias, updateUserData } from "../../../firebase/databaseApi"
import { UserData } from "../../../utils/types"
import { useEffect, useState, useCallback } from "react"
import { CancelOutlined, EditOutlined, InfoOutlined, SaveOutlined } from "@mui/icons-material"
import { FirebaseError } from "firebase/app"
import UserInfoDialog from "./UserInfoDialog"

interface Row extends UserData {
  customerAlias: string | null;
  [key: string]: any;
}

type AlertColor = "error" | "warning" | "info" | "success"

const initialAlert = {
  severity: "",
  text: "",
}

const prepareUserDataForDatabase = (newRow: Row) => {
  const customerData: Partial<UserData> = {}
  Object.keys(newRow).forEach((key) => {
    // Replace all undefined and "" (empty string) values with null,
    // also remove customerAlias field (by setting its value to null)
    if (newRow[key] === undefined 
        || newRow[key] === ""
        || key == "customerAlias") {
      customerData[key] = null
    } else {
      customerData[key] = newRow[key]
    }
  })

  return customerData
}

const UsersTable = () => {
    const [alert, setAlert] = useState(initialAlert)
    const [rows, setRows] = useState<Row[]>([]);
    const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
    const [userInfoDialogOpen, setUserInfoDialogOpen] = useState<boolean>(false)

    ////// USE EFFECTS //////

    useEffect(() => {
      fetchUsers()
    }, [])

    ////// FUNCTIONS //////

    const fetchUsers = async () => {
        // Fetch user data from database
        const userDatas: UserData[] = await getAllUserData()

        // Fetch customer alias where applicable
        const userRows = await Promise.all(userDatas.map(async (userData) => {
          if (userData.connectedCustomerId) {
            const alias = await getCustomerAlias(userData.connectedCustomerId)
            return {...userData, customerAlias: alias}
          } else {
            return userData
          }
        }))

        setRows(userRows as Row[])
    }

    const openEditSongPackagesDialog = () => {
      setUserInfoDialogOpen(true)
    }

    const closeEditSongPackagesDialog = () => {
      setUserInfoDialogOpen(false)
    }

    const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
        if (params.reason === GridRowEditStopReasons.rowFocusOut) {
          event.defaultMuiPrevented = true;
        }
    };

    const handleEditClick = (id: GridRowId) => () => {
      setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    };

    const handleUserInfoClick = (id: GridRowId) => () => {
      // Open dialog
      openEditSongPackagesDialog()
    }

    const handleSaveClick = (id: GridRowId) => () => {
      setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    };

    const handleCancelClick = (id: GridRowId) => async () => {
      // Update table visually
      setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
      });
    };

    const updateUser = async (newRow: Row, oldRow: Row) => {
      // Prepare data for database
      const userData: UserData = prepareUserDataForDatabase(newRow) as UserData

      // Update change in database
      await updateUserData(userData)
    }

    const processRowUpdate = async (newRow: GridRowModel, originalRow: GridRowModel) => {
      // Reset alert
      setAlert(initialAlert)

      // Update customer in database
      await updateUser(newRow as Row, originalRow as Row)

      // Report success
      setAlert({ severity: "success", text: `Uppdaterade användare ${newRow.email} (${newRow.id})` })

      // Fetch customer alias if connected customer id has changed
      let alias = originalRow.customerAlias
      if (newRow.connectedCustomerId != originalRow.connectedCustomerId) {
        // Connected customer id has changed, fetch alias
        alias = await getCustomerAlias(newRow.connectedCustomerId)
      }

      // Update rows visually
      const updatedRow: Partial<Row> = { ...newRow, customerAlias: alias };
      setRows(rows.map((row) => (
        (row.id === newRow.id) ? 
        {...row, ...updatedRow} 
        : row
      )));
      return updatedRow;
    };

    const handleProcessRowUpdateError = useCallback((error: Error) => {
      if (error instanceof FirebaseError) {
        // Firebase error

        // Feedback user
        setAlert({ severity: "error", text: `Misslyckades att uppdatera användare. Error-meddelande: ${error.message}` })
      } else {
        // Other error

        // Feedback user
        setAlert({ severity: "error", text: `Misslyckades att uppdatera användare. Error-meddelande: ${error.message}` })
      }
    }, []);

    const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
      setRowModesModel(newRowModesModel);
    };

    ////// TABLE COLUMN DEFS //////

    const columns: GridColDef[] = [
      { field: 'id', headerName: 'Id', flex: 0.5, editable: false },
      { field: 'name', headerName: 'Namn', flex: 0.5, minWidth: 100, editable: false },
      { field: 'email', headerName: 'Email', flex: 1, minWidth: 100, editable: false },
      { 
        field: 'connectedCustomerId', 
        headerName: 'Kopplad kundlicens', 
        flex: 0.5, 
        editable: true 
      },
      { 
        field: 'customerAlias', 
        headerName: 'Kundalias', 
        flex: 1, 
        editable: false 
      },
      { 
        field: 'created', 
        headerName: 'Skapat', 
        type: 'date', 
        valueFormatter: (params) => (
            params.value ? 
            new Date(params.value).toLocaleDateString('sv-SE') 
            : null
          ),
        flex: 1, 
        maxWidth: 100, 
        editable: false 
      },
      { 
        field: 'lastSignedIn', 
        headerName: 'Senast inloggad', 
        type: 'date', 
        valueFormatter: (params) => (
            params.value ? 
            new Date(params.value).toLocaleDateString('sv-SE') 
            : null
          ),
        flex: 1, 
        maxWidth: 100, 
        editable: false 
      },
      { 
        field: 'isAdmin', 
        headerName: 'Admin', 
        flex: 1,
        minWidth: 70,
        maxWidth: 130,
        editable: true,
        type: 'boolean'
      },
      {
        field: 'actions',
        type: 'actions',
        headerName: 'Actions',
        width: 90,
        cellClassName: 'actions',
        getActions: ({ id }) => {
          const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
  
          if (isInEditMode) {
            return [
              <GridActionsCellItem
                icon={<SaveOutlined />}
                label="Save"
                title="Spara"
                sx={{
                  color: 'primary.main',  
                }}
                onClick={handleSaveClick(id)}
              />,
              <GridActionsCellItem
                icon={<CancelOutlined />}
                label="Cancel"
                title="Avbryt"
                className="textPrimary"
                onClick={handleCancelClick(id)}
                color="inherit"
              />,
            ];
          }
  
          return [
            <GridActionsCellItem
              icon={<EditOutlined />}
              label="Edit"
              title="Redigera"
              className="textPrimary"
              onClick={handleEditClick(id)}
              color="inherit"
            />,
            <GridActionsCellItem
              icon={<InfoOutlined />}
              label="Info"
              title="Info"
              className="textPrimary"
              onClick={handleUserInfoClick(id)}
              color="inherit"
            />,
          ];
        },
      },
    ];

    return (
      <Stack sx={{ height: "100%", width: "100%", display: "flex", flexDirection: "column" }}>
        <Grid item xs="auto">
          <TextParagraph heading="Användare" />
        </Grid>

        <Grid item xs="auto">
          {alert.text ? (
            <Alert
                severity={alert.severity as AlertColor}
                onClose={() => {
                    setAlert(initialAlert)
                }}
            >
                {alert.text}
            </Alert>
            ) : null}
        </Grid>
          
        <Grid item xs sx={{overflow: "auto"}}>
          <DataGrid
            sx={{
              '& .css-t89xny-MuiDataGrid-columnHeaderTitle': {
                fontFamily: "Raleway",
                fontWeight: "bold",
              },
            }}
            rows={rows}
            columns={columns}
            editMode="row"
            initialState={{
                pagination: {
                    paginationModel: { page: 0, pageSize: 100 },
                },
            }}
            pageSizeOptions={[10, 30, 50, 100]}
            rowModesModel={rowModesModel}
            onRowModesModelChange={handleRowModesModelChange}
            onRowEditStop={handleRowEditStop}
            processRowUpdate={processRowUpdate}
            onProcessRowUpdateError={handleProcessRowUpdateError}
          />
        </Grid>

        <Grid item>
          <UserInfoDialog 
            open={userInfoDialogOpen}
            closeDialog={closeEditSongPackagesDialog}
          />
        </Grid>
      </Stack>
    )
}

export default UsersTable
