import { Button } from "@mui/material"

type LinkButtonProps = {
    label: string;
    url: string;
    disabled?: boolean;
    openInCurrentTab?: boolean;
}

const LinkButton = ({label, url, disabled, openInCurrentTab}: LinkButtonProps) => {

    return (
        <Button
            sx={{
                textTransform: "none", // Prevent uppercase text
                color: "black", // Set link color
                textDecoration: "underline", // Add underline
                padding: 0, // Remove padding
                alignSelf: "flex-start",
                width: "auto",
                minWidth: "initial", // Allow the button to shrink to the content size
                background: "none", // Remove background color
                border: "none", // Remove border
                cursor: "pointer", // Change cursor to pointer on hover
                marginTop: "16px",
            }}
            onClick={() => {
                if (openInCurrentTab) {
                    window.open(url, "_self")
                } else {
                    // Default open in new tab
                    window.open(url, "_blank")
                }
            }}
            disabled={disabled}
        >
            {label}
        </Button>
    )
}

export default LinkButton