import { Box } from "@mui/material"
import { ReactNode } from "react"

type GapSize = "tiny" | "small" | "standard" | "heading"

const GAP_SIZES: { [gap in GapSize]: number } = {
    tiny: 3,
    small: 5,
    standard: 6,
    heading: 10,
}

interface TextRowProps {
    children: ReactNode
    gap?: keyof typeof GAP_SIZES | number
}

const TextRow = ({ children, gap = GAP_SIZES.standard }: TextRowProps) => {
    const gapSize = typeof gap === "number" ? gap : GAP_SIZES[gap]

    return <Box sx={{ display: "flex", flexDirection: "row", gap: `${gapSize}px` }}>{children}</Box>
}

export default TextRow
