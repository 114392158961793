import { useEffect, useState } from "react"
import { Button, Grid, useTheme } from "@mui/material"
import Text from "./Text"

interface BasicToggleButtonProps {
    id: string
    title: string
    selected: string | undefined
    setSelected: React.Dispatch<React.SetStateAction<string | undefined>>
}

const BasicToggleButton = ({ id, title, selected, setSelected }: BasicToggleButtonProps) => {
    const [isSelected, setIsSelected] = useState<boolean>(false)
    const theme = useTheme()

    useEffect(() => {
        setIsSelected(id === selected)
    }, [selected])

    return (
        <Button
            onClick={() => setSelected(id)}
            variant={ isSelected ? "contained" : "outlined"}
            sx={{
                textTransform: "none",
                color: "black",
                fontFamily: "Raleway",
                fontSize: "16px",
                textDecoration: "none",
                fontWeight: "600px",
                paddingTop: "0px",
                paddingBottom: "0px",
                minWidth: "0px",
                // border: isSelected ? "1px solid #77e077 " : "1px solid #c2c1c1",
                borderRadius: "50px",
                // backgroundColor: (theme) => isSelected ? theme.palette.primary.main : ""
            }}
            // style={{ backgroundColor: isSelected ? theme.palette.primary : "" }}
        >
            <Text prettify size="standard" bold={isSelected}>
                {title}
            </Text>
        </Button>
    )
}

interface BasicToggleButtonGroupProps {
    items: string[]
    defaultSelected: string
    selected?: string
    setSelected: React.Dispatch<React.SetStateAction<string | undefined>>
    gap?: number
    reset: boolean
}

const BasicToggleButtonGroup = ({ items, defaultSelected, selected, setSelected, gap = 18, reset }: BasicToggleButtonGroupProps) => {
    useEffect(() => {
        if (defaultSelected) {
            setSelected(defaultSelected)
        } else {
            setSelected(items.at(0))
        }
    }, [defaultSelected, reset])

    return (
        <Grid container sx={{ display: "flex", flexDirection: "row", columnGap: `${gap}px`, rowGap: "12px" }}>
            {items.map((item, index) => (
                <Grid item key={index}>
                    <BasicToggleButton id={item} title={item} selected={selected} setSelected={setSelected} />
                </Grid>
            ))}
        </Grid>
    )
}

export default BasicToggleButtonGroup
