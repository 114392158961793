import { useState, ChangeEvent, FormEvent, FocusEvent, useEffect } from "react"
import { Box, TextField, Alert } from "@mui/material"
import * as Yup from "yup"
import { signUp } from "../firebase/authenticationApi"
import { FirebaseError } from "firebase/app"
import { ROUTES } from "../utils/api"
import BasicButton from "../assets/components/BasicButton"
import RouteButton from "../assets/components/RouteButton"
import { ExitToAppOutlined } from "@mui/icons-material"
import { Logo } from "../assets/components/Logo"
import { Image } from "../assets/components/Image/Image"
import GreenInstrument from "../../src/images/green_side003_small.png"

type AlertSeverity = "error" | "warning" | "info" | "success"

interface FormData {
    name?: string | null | undefined
    email: string
    password: string
}

const validationSchema: Yup.Schema<FormData> = Yup.object().shape({
    name: Yup.string().max(50, "Max 50 karaktärer långt").notRequired(),
    email: Yup.string().email("Ogiltig e-postadress").required("Obligatorisk"),
    password: Yup.string().required("Obligatorisk").min(8, "Måste vara minst 8 karaktärer"),
})

const SignUpUI = () => {
    const initialFormState: FormData = {
        name: "",
        email: "",
        password: "",
    }

    const initialErrors: FormData = {
        name: "",
        email: "",
        password: "",
    }

    const initialAlert = {
        severity: "" as AlertSeverity,
        text: "",
    }

    const [formData, setFormData] = useState(initialFormState)
    const [errors, setErrors] = useState(initialErrors)
    const [alert, setAlert] = useState(initialAlert)
    const [handlingSubmit, setHandlingSubmit] = useState(false)

    useEffect(() => {
        setErrors(initialErrors) // Reset errors if form data is edited
    }, [formData])

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }

    const handleBlur = async (e: FocusEvent<HTMLInputElement>) => {
        const name = e.target.name
        const value = e.target.value

        try {
            await (validationSchema as Yup.ObjectSchema<FormData>).validateAt(name, { [name]: value })
            setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }))
        } catch (error: any) {
            if (Yup.ValidationError.isError(error)) {
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    [name]: error.errors[0],
                }))
            }
        }
    }

    const handleSignUp = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()

        setHandlingSubmit(true) // Indicate handling submit
        setAlert(initialAlert) // Reset alert

        try {
            await validationSchema.validate(formData, { abortEarly: false }) // Validate form data
            setErrors(initialErrors) // Reset errors

            await signUp(formData.name, formData.email, formData.password) // Sign up user

            setAlert({ severity: "success", text: "Skapade användare!" }) // Report success
            setFormData(initialFormState) // Reset form
        } catch (error: any) {
            if (Yup.ValidationError.isError(error)) {
                const newErrors: FormData = { ...initialErrors }
                error.inner.forEach((err: any) => {
                    newErrors[err.path as keyof FormData] = err.message
                })
                setErrors(newErrors)
                setAlert({ severity: "error", text: "Det finns fel i formuläret nedan" })
            } else if (error instanceof FirebaseError) {
                switch (error.code) {
                    case "auth/email-already-in-use":
                        setAlert({ severity: "error", text: "Det finns redan ett konto kopplat till e-postadressen" })
                        break
                    case "auth/weak-password":
                        setAlert({ severity: "error", text: "Lösenordet är för svagt eller vanligt, välj ett annat lösenord" })
                        break
                    default:
                        setAlert({ severity: "error", text: "Misslyckades att skapa användare..." })
                }
            } else {
                setAlert({ severity: "error", text: "Misslyckades att skapa användare..." })
            }
        }

        setHandlingSubmit(false) // Indicate done handling submit
    }

    return (
        <Box
            sx={{
                borderRadius: {
                    xs: "16px",
                    lg: 0,
                },
                paddingX: {
                    xs: 4,
                    sm: 14,
                    md: 20,
                    lg: 4,
                },
                paddingY: {
                    xs: 12,
                    md: 16,
                    lg: 4,
                },
                width: "100%",
                height: "100%",
                backgroundColor: (theme) => theme.palette.background.default,
            }}
        >
            <Logo size="l" />

            {alert.text ? <Alert severity={alert.severity}>{alert.text}</Alert> : null}

            <form onSubmit={handleSignUp}>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "10px",
                        marginTop: 4,
                    }}
                >
                    <TextField
                        fullWidth
                        label="Namn (valfri)"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={!!errors.name}
                        helperText={errors.name}
                        disabled={handlingSubmit}
                    />
                    <TextField
                        fullWidth
                        label="E-postadress"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={!!errors.email}
                        helperText={errors.email}
                        disabled={handlingSubmit}
                    />
                    <TextField
                        fullWidth
                        label="Lösenord"
                        name="password"
                        type="password"
                        value={formData.password}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={!!errors.password}
                        helperText={errors.password}
                        disabled={handlingSubmit}
                    />
                    <BasicButton title="Skapa konto" type="submit" variant="contained" disabled={handlingSubmit} progress={handlingSubmit} sx={{ marginTop: 2 }} />
                </Box>
            </form>
            <Box sx={{ marginTop: 10 }}>
                <RouteButton title="Tillbaka till Logga in" route={ROUTES.LOG_IN} icon={ExitToAppOutlined} />
            </Box>
        </Box>
    )
}

const SignUp = () => {
    return (
        <Box
            sx={{
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: {
                    xs: "center",
                    lg: "flex-start",
                },
                alignItems: { xs: "center", lg: "flex-start" },
            }}
        >
            <Box
                sx={{
                    width: {
                        xs: "95%",
                        lg: "50%",
                    },
                    height: {
                        xs: "95%",
                        lg: "100%",
                    },
                    paddingY: {
                        lg: 12,
                    },
                    paddingX: {
                        lg: 20,
                    },
                    zIndex: {
                        xs: 2,
                    },
                    textAlign: "center",
                }}
            >
                <SignUpUI />
            </Box>
            <Box
                sx={{
                    width: {
                        xs: "100%",
                        lg: "50%",
                    },
                    padding: {
                        xs: 0,
                        lg: 1,
                    },
                    zIndex: {
                        xs: 1,
                    },
                    position: {
                        xs: "absolute",
                        lg: "relative",
                    },
                    height: "100%",
                    overflow: "hidden",
                }}
            >
                <Image
                    src={GreenInstrument}
                    description="Image of a Funki instrument"
                    sx={{
                        borderRadius: {
                            xs: 0,
                            lg: 4,
                        },
                        width: "150%",
                        transform: "translateX(-30%) scaleX(-1)",
                    }}
                />
            </Box>
        </Box>
    )
}

export default SignUp
