import React, { useState } from "react"
import { Box } from "@mui/material"
import { Instrument, InstrumentType, Song, SONG_TYPES } from "../../../../utils/types"
import BasicButton from "../../../../assets/components/BasicButton"
import TouchAlternateMode from "./TouchAlternateMode"
import Text from "../../../../assets/components/Text"
import { buttonSounds, joystickSounds, touchSounds } from "../../../../utils/api"
import { Drawer } from "../../Welcome"
import AllSoundsAvailable from "../../Components/AllSoundsAvailable"

interface InstrumentInformationProps {
    instrument: Instrument
    songList: Song[] | undefined
    shouldShowSettings: boolean

    isSmallScreen: boolean
}

const InstrumentInformation = ({ instrument, songList, shouldShowSettings, isSmallScreen }: InstrumentInformationProps) => {
    const [isOpen, setIsOpen] = useState(false)
    const currentInstrumentSong = songList?.find((song: Song) => instrument.currentSong?.id === song.id)
    const [selectedInstrument, setSelectedInstrument] = useState<Instrument>({ deviceId: "", type: "", serialNr: "", color: "" })
    const [isAllSongsDrawerOpen, setIsAllSongsDrawerOpen] = useState(false)

    const toggleDrawer = ({ instrumentId, instrumentType, instrumentSerialNr, color }: { instrumentId?: string; instrumentType?: InstrumentType; instrumentSerialNr?: string; color?: string }) => {
        setIsOpen(!isOpen)
        setSelectedInstrument({
            color: color ?? "",
            deviceId: instrumentId ?? "",
            type: instrumentType ?? "",
            serialNr: instrumentSerialNr ?? "",
        })
    }

    const toggleAllSongsDrawer = ({
        instrumentId,
        instrumentType,
        instrumentSerialNr,
        color,
    }: {
        instrumentId?: string
        instrumentType?: InstrumentType
        instrumentSerialNr?: string
        color?: string
    }) => {
        setIsAllSongsDrawerOpen(!isAllSongsDrawerOpen)
        setSelectedInstrument({
            color: color ?? "",
            deviceId: instrumentId ?? "",
            type: instrumentType ?? "",
            serialNr: instrumentSerialNr ?? "",
        })
    }

    function getInstrumentSound() {
        if (instrument.currentSong?.sound) {
            if (instrument.type === "buttons") {
                return buttonSounds.find((sound) => sound.id === instrument.currentSong?.sound)
            }
            if (instrument.type === "joysticks") {
                return joystickSounds.find((sound) => sound.id === instrument.currentSong?.sound)
            }
            if (instrument.type === "touch") {
                return touchSounds.find((sound) => sound.id === instrument.currentSong?.sound)
            }
        }
        return null
    }

    const instrumentSound = getInstrumentSound()
    const soundName = instrumentSound?.name || "Unknown sound"

    return (
        <Box
            sx={{
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "left",
                justifyContent: "left",
            }}
        >
            <Box sx={{ flexGrow: 1 }}>
                {currentInstrumentSong?.title && (
                    <Box>
                        <Text size={"tiny"} bold>
                            {currentInstrumentSong.type === SONG_TYPES.MUSIC ? "Musik" : currentInstrumentSong.type === SONG_TYPES.SOUNDBANKS ? "Ljudlandskap" : "Tonart"}
                        </Text>
                        <Text>{currentInstrumentSong.title}</Text>
                    </Box>
                )}
                {instrumentSound && (
                    <Box paddingTop="0.5rem">
                        <Text size={"tiny"} bold>
                            Sound
                        </Text>
                        <Box sx={{ display: "flex", columnGap: "0.25rem", alignItems: "center" }}>
                            <BasicButton
                                variant="outlined"
                                title={soundName}
                                sx={{ padding: "2px" }}
                                onClick={() => toggleDrawer({ instrumentId: instrument.deviceId, instrumentType: instrument.type, instrumentSerialNr: instrument.serialNr })}
                            />
                        </Box>
                    </Box>
                )}
                {/* {currentInstrumentSong?.touchMode && currentInstrumentSong?.touchMode === "BOTH" && <TouchAlternateMode instrument={instrument} />} */}
            </Box>
            <BasicButton
                title="Välj ljud"
                variant="text"
                sx={{ padding: "2px", width: "2rem" }}
                onClick={() => toggleAllSongsDrawer({ instrumentId: instrument.deviceId, instrumentType: instrument.type, instrumentSerialNr: instrument.serialNr })}
            />
            <Drawer variant="bottom" isOpen={isAllSongsDrawerOpen} toggleDrawer={() => toggleAllSongsDrawer({})} heading="Välj ljud">
                <AllSoundsAvailable songList={songList} selectedInstrument={selectedInstrument} />
            </Drawer>
        </Box>
    )
}

export default InstrumentInformation
