import {  Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material"
import Text from "../../../assets/components/Text"
import BasicButton from "../../../assets/components/BasicButton"
import LinkButton from "../../../assets/components/LinkButton";

interface UserInfoDialogProps {
    open: boolean;
    closeDialog: () => void;
}

const UserInfoDialog = ({ open, closeDialog }: UserInfoDialogProps) => {

    return (
        <Dialog
            open={open}
            onClose={closeDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
            <Text size="heading" bold>
                Info - Utökad hantering av användare
            </Text>
            </DialogTitle>
            <DialogContent>
                <Text size="small">
                    {`För tillfället går det inte att utföra handlingar såsom att radera användarkonton, ändra email, etc, 
                    från detta admin-gränssnitt. Sådana handlingar kan endast utföras i Firebase-konsolen:`}
                </Text>
                <LinkButton 
                    label="https://console.firebase.google.com/" 
                    url="https://console.firebase.google.com/" 
                />
            </DialogContent>
            <DialogActions>
                <BasicButton title="Okej" onClick={closeDialog} />
            </DialogActions>
        </Dialog>
    );
}

export default UserInfoDialog