import { useState, ChangeEvent, FormEvent, useEffect } from "react"
import { TextField, Button, Alert, Box } from "@mui/material"
import * as Yup from "yup"
import { checkOobCode, completePasswordReset } from "../firebase/authenticationApi"
import { FirebaseError } from "firebase/app"
import { ROUTES } from "../utils/api"
import { useLocation, useNavigate } from "react-router-dom"
import BasicButton from "../assets/components/BasicButton"

type AlertColor = "error" | "warning" | "info" | "success"

const ResetPassword = () => {
    const newPasswordSchema: Yup.Schema<string | undefined> = Yup.string().min(8, "Måste vara minst 8 karaktärer")

    const initialNewPasswordState = ""
    const initialError = ""
    const initialAlert = {
        severity: "",
        text: "",
    }

    const navigate = useNavigate()
    const queryParams = new URLSearchParams(useLocation().search)
    const oobCode = queryParams.get("oobCode") ?? ""

    const [newPassword, setNewPassword] = useState(initialNewPasswordState)
    const [error, setError] = useState(initialError)
    const [alert, setAlert] = useState(initialAlert)
    const [invalidOobCode, setInvalidOobCode] = useState(false)
    const [passwordSaved, setPasswordSaved] = useState(false)
    const [handlingSubmit, setHandlingSubmit] = useState(false)

    useEffect(() => {
        const validityCheckOobCode = async () => {
            try {
                // Check oobCode
                await checkOobCode(oobCode)
            } catch (error) {
                if (error instanceof FirebaseError) {
                    // Firebase error
                    setInvalidOobCode(true)

                    // Feedback to user
                    switch (error.code) {
                        case "auth/expired-action-code":
                            setAlert({ severity: "error", text: `Länken för att återställa lösenordet har gått ut, vänligen gör en ny förfrågan om att återställa lösenordet` })
                            break
                        case "auth/invalid-action-code":
                            setAlert({
                                severity: "error",
                                text: `Länken för att återställa lösenordet är antingen korrupt eller har redan använts, vänligen gör ny förfrågan om att återställa lösenordet`,
                            })
                            break
                        default:
                            setAlert({
                                severity: "error",
                                text: `Länken för att återställa lösenordet är antingen korrupt eller har redan använts, vänligen gör ny förfrågan om att återställa lösenordet`,
                            })
                            break
                    }
                }
            }
        }

        validityCheckOobCode()
    }, [oobCode])

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        setNewPassword(e.target.value)
    }

    const handleResetPassword = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()

        // Indicate handling submit
        setHandlingSubmit(true)

        // Reset alert
        setAlert(initialAlert)

        try {
            // Confirm oobCode
            await checkOobCode(oobCode)

            // Validate form data
            await newPasswordSchema.validate(newPassword, { abortEarly: false })
            setError(initialError) // Reset newPassword error

            // Complete password reset
            await completePasswordReset(oobCode, newPassword)

            // Password saved
            setPasswordSaved(true)

            // Report success
            setAlert({ severity: "success", text: `Nya lösenordet har sparats` })
        } catch (error: any) {
            if (Yup.ValidationError.isError(error)) {
                // Validation error
                setError(error.message)

                // Report validation failure
                setAlert({ severity: "error", text: "Det finns fel i formuläret nedan" })
            } else if (error instanceof FirebaseError) {
                // Firebase error
                console.log("error.code", error.code)
                switch (error.code) {
                    case "auth/expired-action-code":
                        // Report user not found error
                        setAlert({ severity: "error", text: `Länken för att återställa lösenordet har gått ut, vänligen gör en ny förfrågan om att återställa lösenordet` })
                        console.log("ERROR:", error.message)
                        break
                    default:
                        // Report general Firebase error
                        setAlert({ severity: "error", text: `Misslyckades att sätta nytt lösenord` })
                        console.log("ERROR:", error.message)
                }
            } else {
                // Other error
                // Report general failure
                setAlert({ severity: "success", text: `Misslyckades att sätta nytt lösenord` })
                console.log("ERROR:", error.message)
            }
        }

        // Indicate done handling submit
        setHandlingSubmit(false)
    }

    return (
        <Box
            sx={{
                paddingTop: 5,
                display: "flex",
                justifyContent: "center",
                alignItems: "flex-start",
                flexDirection: "column",
                height: "100%",
                width: "330px",
                margin: "0 auto",
                padding: "16px",
            }}
        >
            <h4>Välj ditt nya lösenord</h4>
            <p></p>

            {alert.text ? <Alert severity={alert.severity as AlertColor}>{alert.text}</Alert> : null}

            <form onSubmit={handleResetPassword}>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        gap: "10px",
                    }}
                >
                    <TextField
                        fullWidth
                        label="Nytt lösenord"
                        name="newPassword"
                        variant="standard"
                        type="password"
                        value={newPassword}
                        onChange={handleChange}
                        error={!!error}
                        helperText={error}
                        disabled={invalidOobCode || passwordSaved || handlingSubmit}
                    />
                    <BasicButton title="Spara lösenord" type="submit" disabled={invalidOobCode || passwordSaved || handlingSubmit} progress={handlingSubmit} />
                </Box>
            </form>
            <Button
                sx={{
                    textTransform: "none", // Prevent uppercase text
                    color: "black", // Set link color
                    textDecoration: "underline", // Add underline
                    padding: 0, // Remove padding
                    minWidth: "initial", // Allow the button to shrink to the content size
                    background: "none", // Remove background color
                    border: "none", // Remove border
                    cursor: "pointer", // Change cursor to pointer on hover
                    marginTop: "16px",
                }}
                onClick={() => {
                    navigate(ROUTES.LOG_IN)
                }}
            >
                Till Logga in
            </Button>
        </Box>
    )
}

export default ResetPassword
