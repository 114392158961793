import { Box, InputAdornment, TextField } from "@mui/material"
import { Search } from "@mui/icons-material"
import { isMobile } from "mobile-device-detect"

type SearchBarProps = {
    placeholder: string
    setSearchValue: any
    label?: string
}

const SearchBar = ({ placeholder, label = "", setSearchValue }: SearchBarProps) => {
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setSearchValue(event.target.value)
    }

    return (
        <Box
            component="form"
            sx={{
                "& .MuiTextField-root": { m: 1, width: "25ch" },
                "& .MuiOutlinedInput-root": { borderRadius: "50px" },
            }}
            autoComplete="off"
        >
            <TextField
                id="outlined-search"
                size="small"
                label={label}
                placeholder={placeholder}
                type="search"
                fullWidth={true}
                onChange={handleChange}
                sx={{ minWidth: isMobile ? "90%" : "auto" }}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <Search />
                        </InputAdornment>
                    ),
                }}
            />
        </Box>
    )
}

export default SearchBar
