import { useState, ChangeEvent, FormEvent, useEffect } from "react"
import { TextField, Alert, Box, LinearProgress } from "@mui/material"
import { FirebaseError } from "firebase/app"
import { updateCustomerForUser, validActivationCode } from "../firebase/databaseApi"
import { useCurrentUser, useReloadCurrentUser } from "../hooks/contexts/currentUserContext"
import BasicButton from "../assets/components/BasicButton"
import { Image } from "../assets/components/Image/Image"
import { Logo } from "../assets/components/Logo"
import RouteButton from "../assets/components/RouteButton"
import { ExitToAppOutlined } from "@mui/icons-material"
import Text from "../assets/components/Text"
import { ROUTES } from "../utils/api"
import { logOut } from "../firebase/authenticationApi"
import YellowInstrument from "../../src/images/yellow_side001_small.png"

type AlertSeverity = "error" | "warning" | "info" | "success"

function sleep(seconds: number) {
    return new Promise((resolve) => setTimeout(resolve, seconds * 1000))
}

const ActivateAccountUI = () => {
    const initialFieldState = ""
    const initialError = ""
    const initialAlert = {
        severity: "",
        text: "",
    }

    const [activationCode, setActivationCode] = useState(initialFieldState)
    const [error, setError] = useState(initialError)
    const [alert, setAlert] = useState(initialAlert)
    const [accountActivated, setAccountActivated] = useState(false)
    const [handlingSubmit, setHandlingSubmit] = useState(false)
    const currentUser = useCurrentUser()
    const reloadCurrentUser = useReloadCurrentUser()

    useEffect(() => {
        setError(initialError) // Reset errors if activation code is edited
    }, [activationCode])

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        setActivationCode(e.target.value)
    }

    const handleActivate = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()

        // Indicate handling submit
        setHandlingSubmit(true)

        // Reset alert
        setAlert(initialAlert)

        // Reset error
        setError(initialError)

        try {
            // Try to activate account
            if (await validActivationCode(activationCode)) {
                // The activation code is valid

                // Update customer for the user in database
                await updateCustomerForUser(currentUser?.auth?.uid, activationCode)

                // Set account activated
                setAccountActivated(true)

                // Report success
                setAlert({ severity: "success", text: `Aktiveringen lyckades` })

                // Stylistic 3-second sleep
                await sleep(3)

                // Trigger reload of current user to fetch new data
                reloadCurrentUser()
            } else {
                // Activation code is invalid

                // Feedback user
                setError(`Den angivna aktiveringskoden är ogiltig`)
            }
        } catch (error: any) {
            if (error instanceof FirebaseError) {
                // Firebase error

                // Feedback user
                setAlert({ severity: "error", text: `Något gick fel vid aktiveringen, testa igen eller kontakta oss på Funki om problemet kvarstår` })
            } else {
                // Other error

                // Feedback user
                setAlert({ severity: "error", text: `Något gick fel vid aktiveringen, testa igen eller kontakta oss på Funki om problemet kvarstår` })
            }
        }

        // Indicate done handling submit
        setHandlingSubmit(false)
    }

    return (
        <Box
            sx={{
                borderRadius: {
                    xs: "16px",
                    lg: 0,
                },
                paddingX: {
                    xs: 4,
                    sm: 14,
                    md: 20,
                    lg: 4,
                },
                paddingY: {
                    xs: 12,
                    md: 16,
                    lg: 4,
                },
                width: "100%",
                height: "100%",
                backgroundColor: (theme) => theme.palette.background.default,
            }}
        >
            <Logo size="l" />
            <Box sx={{ paddingBottom: 4 }}>
                <Text size={"giant"}>Ditt konto är inte kopplat till en licens ännu</Text>
            </Box>
            <Text>För att aktivera ditt konto och ansluta till en licens, skriv in aktiveringskoden för licensen nedan.</Text>

            {alert.text ? (
                <Alert
                    severity={alert.severity as AlertSeverity}
                    sx={{
                        width: "100%",
                    }}
                >
                    {alert.text}
                </Alert>
            ) : null}

            {accountActivated ? (
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "stretch",
                        gap: "20px",
                        width: "100%",
                        marginTop: "20px",
                        fontWeight: "bold",
                        textAlign: "center",
                    }}
                >
                    <LinearProgress color="inherit" />
                    <p>Laddar...</p>
                </Box>
            ) : (
                <>
                    <form onSubmit={handleActivate}>
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                                gap: "10px",
                                marginTop: 4,
                            }}
                        >
                            <TextField
                                fullWidth
                                label="Aktiveringskod"
                                name="activationCode"
                                value={activationCode}
                                onChange={handleChange}
                                error={!!error}
                                helperText={error}
                                disabled={accountActivated || handlingSubmit}
                            />
                            <BasicButton
                                title="Aktivera"
                                type="submit"
                                variant="contained"
                                disabled={!activationCode || accountActivated || handlingSubmit}
                                progress={handlingSubmit && !accountActivated}
                                sx={{ marginTop: 2 }}
                            />
                            {/* <BasicButton title="Logga in" type="submit" variant="contained" disabled={handlingSubmit} progress={handlingSubmit} sx={{ marginTop: 2 }} /> */}
                        </Box>
                    </form>
                    <Box sx={{ marginTop: 10 }}>
                        <RouteButton title="Har du ingen aktiveringskod?" route="https://funki.se/#/contact" target="_blank" icon={ExitToAppOutlined} />
                    </Box>
                    <Box sx={{ marginTop: "16px" }}>
                        <RouteButton title="Tillbaka till Logga in" route={ROUTES.LOG_IN} icon={ExitToAppOutlined} onClick={logOut} />
                    </Box>
                </>
            )}
        </Box>
    )
}

const ActivateAccount = () => {
    return (
        <Box
            sx={{
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: {
                    xs: "center",
                    lg: "flex-start",
                },
                alignItems: { xs: "center", lg: "flex-start" },
            }}
        >
            <Box
                sx={{
                    width: {
                        xs: "95%",
                        lg: "50%",
                    },
                    height: {
                        xs: "95%",
                        lg: "100%",
                    },
                    paddingY: {
                        lg: 12,
                    },
                    paddingX: {
                        lg: 20,
                    },
                    zIndex: {
                        xs: 2,
                    },
                    textAlign: "center",
                }}
            >
                <ActivateAccountUI />
            </Box>
            <Box
                sx={{
                    width: {
                        xs: "100%",
                        lg: "50%",
                    },
                    padding: {
                        xs: 0,
                        lg: 1,
                    },
                    zIndex: {
                        xs: 1,
                    },
                    position: {
                        xs: "absolute",
                        lg: "relative",
                    },
                    height: "100%",
                    overflow: "hidden",
                }}
            >
                <Image
                    src={YellowInstrument}
                    description="Image of a Funki instrument"
                    sx={{
                        borderRadius: {
                            xs: 0,
                            lg: 4,
                        },
                        width: "150%",
                        transform: "translateX(-25%)",
                    }}
                />
            </Box>
        </Box>
    )
}

export default ActivateAccount
