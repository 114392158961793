import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material"
import { ExpandLess, ExpandMore } from "@mui/icons-material"

interface BasicAccordionProps {
    summary: JSX.Element
    details: JSX.Element
    reverse?: boolean
    noBorder?: boolean
    compact?: boolean
    expanded?: boolean
    onChange?: (isExpanded: boolean) => void
}

const BasicAccordion = ({ summary, details, reverse, noBorder, compact, expanded, onChange }: BasicAccordionProps) => {
    return (
        <Accordion
            disableGutters
            expanded={expanded}
            onChange={(_, isExpanded) => {
                if (onChange) {
                    onChange(isExpanded)
                }
            }}
            sx={{
                backgroundColor: "inherit",
                boxShadow: "none",
                border: noBorder ? "none" : "0.667px solid",
                "&::before": {
                    display: "none",
                },
                borderRadius: "4px",
                borderColor: "rgba(128, 128, 128, .25)",
                display: "flex",
                flexDirection: reverse ? "column-reverse" : "column",
            }}
        >
            <AccordionSummary
                expandIcon={reverse ? <ExpandLess /> : <ExpandMore />}
                sx={{
                    padding: compact ? "0px" : "0px 10px",
                    marginTop: compact ? "6px" : "",
                    minHeight: "0px",
                    display: "flex",
                    justifyContent: compact ? "flex-start" : "space-between",
                    "& .MuiAccordionSummary-content": { flexGrow: 0, margin: compact ? "0px" : "" },
                    "& .MuiAccordionSummary-expandIcon": { order: -1 },
                }}
            >
                {summary}
            </AccordionSummary>
            <AccordionDetails sx={{ padding: compact ? "0px" : "0px 10px 10px" }}>{details}</AccordionDetails>
        </Accordion>
    )
}

export default BasicAccordion
