import React, { createContext, useContext, useRef, useState, useEffect } from "react"
import { BackingTrack } from "../../utils/types"

interface AudioPlayerContextProps {
    isPlaying: boolean
    togglePlay: () => void
    currentTrack: BackingTrack | null
    setCurrentTrack: (track: BackingTrack) => void
    currentTime: number
    duration: number
    volume: number
    setVolume: (volume: number) => void
    progress: number
    setProgress: (progress: number) => void
    handleProgressChange: (event: any, newValue: number | number[]) => void
}

const AudioPlayerContext = createContext<AudioPlayerContextProps | undefined>(undefined)

export const useAudioPlayer = () => {
    const context = useContext(AudioPlayerContext)
    if (!context) {
        throw new Error("useAudioPlayer must be used within AudioPlayerProvider")
    }
    return context
}

export const AudioPlayerProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const audioRef = useRef<HTMLAudioElement>(null)
    const [isPlaying, setIsPlaying] = useState(false)
    const [currentTrack, setCurrentTrack] = useState<BackingTrack | null>(null)
    const [currentTime, setCurrentTime] = useState(0)
    const [duration, setDuration] = useState(0)
    const [volume, setVolume] = useState(100)
    const [progress, setProgress] = useState(0)

    useEffect(() => {
        if (audioRef.current) {
            audioRef.current.addEventListener("loadedmetadata", () => {
                setDuration(audioRef.current?.duration || 0)
            })
        }
    }, [currentTrack])

    const togglePlay = () => {
        if (audioRef.current) {
            if (isPlaying) {
                audioRef.current.pause()
            } else {
                audioRef.current.play()
            }
            setIsPlaying(!isPlaying)
        }
    }

    const handleTimeUpdate = () => {
        if (audioRef.current) {
            setCurrentTime(audioRef.current.currentTime)
            setProgress((audioRef.current.currentTime / audioRef.current.duration) * 100)
        }
    }

    const handleVolumeChange = (newVolume: number) => {
        setVolume(newVolume)
        if (audioRef.current) {
            audioRef.current.volume = newVolume / 100
        }
    }

    const handleProgressChange = (event: any, newValue: number | number[]) => {
        const newProgress = typeof newValue === "number" ? newValue : newValue[0]
        setProgress(newProgress)
        if (audioRef.current) {
            audioRef.current.currentTime = (newProgress / 100) * audioRef.current.duration
        }
    }

    return (
        <AudioPlayerContext.Provider
            value={{
                isPlaying,
                togglePlay,
                currentTrack,
                setCurrentTrack,
                currentTime,
                duration,
                volume,
                setVolume: handleVolumeChange,
                progress,
                setProgress,
                handleProgressChange,
            }}
        >
            {children}
            <audio ref={audioRef} src={currentTrack?.src} onTimeUpdate={handleTimeUpdate} />
        </AudioPlayerContext.Provider>
    )
}
