import { useCurrentUser } from "../hooks/contexts/currentUserContext"
import { Box, Divider, Grid } from "@mui/material"
import { Navigate, Route, Routes, useLocation, useNavigate } from "react-router-dom"
import RouteButton from "../assets/components/RouteButton"
import { ROUTES } from "../utils/api"
import { AudioFileOutlined, Diversity3Outlined, PeopleAltOutlined } from "@mui/icons-material"
import { useEffect } from "react"
import SoundbanksAndSongs from "./admin_subpages/SoundsbanksAndSongs"
import Customers from "./admin_subpages/Customers"
import Users from "./admin_subpages/Users"

const Admin = () => {
    const currentUser = useCurrentUser()
    const navigate = useNavigate()
    const curPath = useLocation().pathname

    useEffect(() => {
        if (curPath == ROUTES.ADMIN.MAIN) {
            // Default subpage in admin page
            navigate(ROUTES.ADMIN.CUSTOMERS.fullPath, { replace: true })
        }
    }, [curPath])

    return (
        <Grid container sx={{ height: "100%" }}>
            <Grid item sx={{ minWidth: "150px", padding: "32px 24px" }}>
                <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start", gap: "10px" }}>
                    <RouteButton title="Kunder" route={ROUTES.ADMIN.CUSTOMERS.fullPath} icon={Diversity3Outlined} />
                    <RouteButton title="Låtar och ljudbank" route={ROUTES.ADMIN.SOUNDBANKS_AND_SONGS.fullPath} icon={AudioFileOutlined} />
                    <RouteButton title="Användare" route={ROUTES.ADMIN.USERS.fullPath} icon={PeopleAltOutlined} />
                </Box>
            </Grid>
            <Grid item sx={{ padding: "32px 0px", height: "100%" }}>
                <Divider orientation="vertical" sx={{ borderColor: "#808080" }} />
            </Grid>
            <Grid item xs sx={{ overflow: "auto", height: "100%", padding: "32px 64px" }}>
                <Routes>
                    <Route path={ROUTES.ADMIN.MAIN} element={<Navigate to={ROUTES.ADMIN.CUSTOMERS.fullPath} replace />} />
                    <Route path={ROUTES.ADMIN.CUSTOMERS.subPath} element={<Customers />} />
                    <Route path={ROUTES.ADMIN.SOUNDBANKS_AND_SONGS.subPath} element={<SoundbanksAndSongs />} />
                    <Route path={ROUTES.ADMIN.USERS.subPath} element={<Users />} />
                </Routes>
            </Grid>
        </Grid>
    )
}

export default Admin
