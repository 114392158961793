import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material"
import { GridRowModes, GridRowModesModel, GridRowsProp, GridToolbarContainer } from '@mui/x-data-grid'
import { AddReactionOutlined } from "@mui/icons-material"
import Text from "../../../assets/components/Text"
import BasicButton from "../../../assets/components/BasicButton"
import { useState } from "react"
import { Song } from "../../../utils/types"
import { generateNewCustomerId } from "../../../firebase/databaseApi"

interface DialogProps {
    open: boolean
    heading: string
    text: string
}

interface CustomersToolbarProps {
setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
setRowModesModel: (
    newModel: (oldModel: GridRowModesModel) => GridRowModesModel,
) => void;
rows: Song[];
}

const CustomersToolbar = (props: CustomersToolbarProps) => {
    const { setRows, setRowModesModel, rows } = props;
    const [dialog, setDialog] = useState<DialogProps>({open: false, heading: "", text: ""});
  
    const createNewCustomer = () => {
        // Generate new id for song
        const id = generateNewCustomerId()
  
        // Abort if no id could be generated
        if (!id) {
          openDialog("Handling misslyckades", "Misslyckades att skapa ny kund då ett nytt kund-id inte kunde genereras från databasen.")
          return
        }
  
        // Update rows visually and start edit on the new row
        setRows((oldRows) => [...oldRows, {id, isNew: true, paymentPlan: "one-month", paymentMethod: "invoice"}]);
        setRowModesModel((oldModel) => ({
            ...oldModel,
            [id]: { mode: GridRowModes.Edit, fieldToFocus: 'alias' },
        }));
    };
  
    const openDialog = (heading: string, text: string) => {
      setDialog({
        open: true, 
        heading: heading, 
        text: text
      })
    };
  
    const closeDialog = () => {
      setDialog({...dialog, open: false});
    };
  
    return (
      <GridToolbarContainer>
        <BasicButton title="Skapa ny kund" icon={AddReactionOutlined} onClick={createNewCustomer} />
        <Dialog
          open={dialog.open}
          onClose={closeDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            <Text size="heading" bold>
              {dialog.heading}
            </Text>
          </DialogTitle>
          <DialogContent>
              <Text>
                {dialog.text}
              </Text>
          </DialogContent>
          <DialogActions>
            <BasicButton title="Okej" onClick={closeDialog} />
          </DialogActions>
        </Dialog>
      </GridToolbarContainer>
    );
}

export default CustomersToolbar