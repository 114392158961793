import { Alert, Grid, Stack } from "@mui/material"
import { DataGrid, GridActionsCellItem, GridColDef, GridEventListener, GridRowEditStopReasons, GridRowId, GridRowModel, GridRowModes, GridRowModesModel } from "@mui/x-data-grid"
import TextParagraph from "../../../assets/components/TextParagraph"
import { deleteSong, generateNewSongId, getAllSongData, updateSongData, updateSongImage } from "../../../firebase/databaseApi"
import { Song } from "../../../utils/types"
import { useEffect, useState, useCallback, useRef, ChangeEvent } from "react"
import { AddPhotoAlternateOutlined, CancelOutlined, DeleteOutline, EditOutlined, QueueMusicOutlined, SaveOutlined } from "@mui/icons-material"

import { FirebaseError } from "firebase/app"
import SongsAndSoundsToolbar from "./SongsAndSoundsToolbar"
import { deleteSongImage, uploadSongImage } from "../../../firebase/storageApi"
import EditPackage from "./EditPackage"

interface Row extends Song {
    isNew?: boolean
    [key: string]: any
}

type AlertColor = "error" | "warning" | "info" | "success"

const initialAlert = {
    severity: "",
    text: "",
}

const prepareSongForDatabase = (newRow: Row) => {
    const songData: Partial<Song> = {}
    Object.keys(newRow).forEach((key) => {
        // Replace all undefined and "" (empty string) values with null
        if (newRow[key] === undefined || newRow[key] === "") {
            songData[key] = null
        } else {
            songData[key] = newRow[key]
        }
    })

    // Remove isNew prop
    delete songData.isNew

    return songData
}

const SongsAndSoundsTable = () => {
    const [alert, setAlert] = useState(initialAlert)
    const [rows, setRows] = useState<Row[]>([])
    const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({})
    const [songIdForImage, setSongIdForImage] = useState<string | null>(null)
    const imageUploadRef = useRef<HTMLInputElement>(null)
    const [songIdToEdit, setSongIdToEdit] = useState<string | null>(null)
    const [editSongPackagesDialogOpen, setEditSongPackagesDialogOpen] = useState<boolean>(false)

    ////// USE EFFECTS //////

    useEffect(() => {
        fetchSongs()
    }, [])

    ////// FUNCTIONS //////

    const fetchSongs = async () => {
        const songs: Song[] = await getAllSongData()
        setRows(songs)
    }

    const handleRowEditStop: GridEventListener<"rowEditStop"> = (params, event) => {
        if (params.reason === GridRowEditStopReasons.rowFocusOut) {
            event.defaultMuiPrevented = true
        }
    }

    const handleEditClick = (id: GridRowId) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } })
    }

    const handleAddImageClick = (id: GridRowId) => () => {
        // Trigger hidden input file upload click, which will
        // trigger the handleImageUpload() function
        setSongIdForImage(id as string)
        imageUploadRef.current?.click()
    }

    const handleImageUpload = async (event: ChangeEvent<HTMLInputElement>) => {
        // Reset alert
        setAlert(initialAlert)

        // Check that there is a chosen file and song id exist
        if (!event.target.files || event.target.files.length < 1 || !songIdForImage) {
            // Could feedback error to user here before aborting
            return
        }

        // Get file
        const file = event.target.files[0]

        try {
            // Get the row that image is uploaded for
            const editedRow = rows.find((row) => row.id === songIdForImage)

            // Sanity check that row exist
            if (!editedRow) {
                // Could not find the row for some reason
                throw Error(`Unable to find song with id ${songIdForImage}`)
            }

            // Remove old image in storage if there is one
            if (editedRow.img) {
                await deleteSongImage(editedRow.img)
            }

            // Upload image to storage and retrieve url with token
            const uploadedImageUrl = await uploadSongImage(file)

            // Update image for song in database
            await updateSongImage(editedRow.id, uploadedImageUrl)

            // Update rows visually to reflect the change
            setRows(rows.map((row) => (row.id === songIdForImage ? { ...row, img: uploadedImageUrl } : row)))

            // Feedback success to user
            setAlert({ severity: "success", text: `Laddade upp bild ${file.name}` })
        } catch (error: any) {
            // Feedback error to user
            setAlert({ severity: "error", text: `Misslyckades att ladda upp bild. Error-meddelande: ${error.message}` })

            // TODO: Try to remove uploaded image in storage if it
            // was uploaded before error occurred
        }
    }

    const handleSaveClick = (id: GridRowId) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } })
    }

    const handleDeleteClick = (id: GridRowId) => async () => {
        // Get the row with this id
        const rowToDelete = rows.find((row) => row.id === id)

        try {
            // Delete song in database
            await deleteSong(id as string, rowToDelete?.songPackage)

            // Feedback success to user
            setAlert({ severity: "success", text: `Tog bort ${id}` })
        } catch (error: any) {
            // Abort delete and feedback user
            setAlert({ severity: "error", text: `Misslyckades att ta bort ${id}. Error-meddelande: ${error.message}` })
            return
        }

        // Update table visually
        setRows(rows.filter((row) => row.id !== id))
    }

    const handleCancelClick = (id: GridRowId) => async () => {
        // Update table visually
        setRowModesModel({
            ...rowModesModel,
            [id]: { mode: GridRowModes.View, ignoreModifications: true },
        })

        const editedRow = rows.find((row) => row.id === id)
        if (editedRow!.isNew) {
            setRows(rows.filter((row) => row.id !== id))
        }
    }

    const updateSong = async (newRow: Row, oldRow: Row) => {
        // Prepare data for database
        const songData: Song = prepareSongForDatabase(newRow) as Song

        // Update change in database
        await updateSongData(songData, oldRow.id, oldRow.songPackage)
    }

    const processRowUpdate = async (newRow: GridRowModel, originalRow: GridRowModel) => {
        // Reset alert
        setAlert(initialAlert)

        // Update song in database
        await updateSong(newRow as Row, originalRow as Row)

        // Report success
        if (newRow.isNew) {
            setAlert({ severity: "success", text: `Lade till ${newRow.id}` })
        } else {
            setAlert({ severity: "success", text: `Uppdaterade ${newRow.id}` })
        }

        // Update rows visually
        const updatedRow: Partial<Row> = { ...newRow, isNew: false }
        setRows(rows.map((row) => (row.id === newRow.id || row.id === originalRow.id ? { ...row, ...updatedRow } : row)))
        return updatedRow
    }

    const handleProcessRowUpdateError = useCallback((error: Error) => {
        if (error instanceof FirebaseError) {
            // Firebase error

            // Feedback user
            setAlert({ severity: "error", text: `Misslyckades att uppdatera låt. Error-meddelande: ${error.message}` })
        } else {
            // Other error

            // Feedback user
            setAlert({ severity: "error", text: `Misslyckades att uppdatera låt. Error-meddelande: ${error.message}` })
        }
    }, [])

    const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
        setRowModesModel(newRowModesModel)
    }

    const openEditSongPackagesDialog = () => {
        setEditSongPackagesDialogOpen(true)
    }

    const closeEditSongPackagesDialog = () => {
        setEditSongPackagesDialogOpen(false)
    }

    const handleEditSongPackagesClick = (id: GridRowId) => () => {
        // Set customer id to edit
        setSongIdToEdit(id as string)

        // Open dialog
        openEditSongPackagesDialog()
    }

    ////// TABLE COLUMN DEFS //////

    const columns: GridColDef[] = [
        { field: "id", headerName: "Id", flex: 1, minWidth: 100, maxWidth: 230, editable: true },
        { field: "title", headerName: "Titel", flex: 1, minWidth: 100, editable: true },
        {
            field: "img",
            headerName: "Bild",
            renderCell: (params) => <img src={params.value} height="85%" />,
            flex: 1,
            minWidth: 62,
            maxWidth: 80,
            editable: false,
        },
        { field: "description", headerName: "Beskrivning", flex: 1, editable: true },
        {
            field: "type",
            headerName: "Typ",
            flex: 1,
            maxWidth: 130,
            editable: true,
            type: "singleSelect",
            valueOptions: [
                { value: "music", label: "Musik" },
                { value: "soundbanks", label: "Ljudlandskap" },
                { value: "key", label: "Tonart" },
            ],
        },
        { field: "songPackage", headerName: "Låtpaket", flex: 1, maxWidth: 230, editable: true },
        { field: "category", headerName: "Kategori", flex: 1, maxWidth: 230, editable: true },
        { field: "artist", headerName: "Artist", flex: 1, editable: true },
        { field: "genre", headerName: "Genre", flex: 1, maxWidth: 130, editable: true },
        { field: "keytone", headerName: "Tonart", flex: 1, maxWidth: 130, editable: true },
        { field: "tempo", headerName: "Tempo", flex: 1, maxWidth: 130, editable: true },
        {
            field: "hasSections",
            headerName: "Sektioner",
            flex: 1,
            minWidth: 90,
            maxWidth: 130,
            editable: true,
            type: "boolean",
        },
        {
            field: "hasBackingTrack",
            headerName: "Backingtrack",
            flex: 1,
            minWidth: 90,
            maxWidth: 130,
            editable: true,
            type: "boolean",
        },
        {
            field: "actions",
            type: "actions",
            headerName: "Actions",
            flex: 1,
            minWidth: 130,
            cellClassName: "actions",
            getActions: ({ id }) => {
                const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit

                if (isInEditMode) {
                    return [
                        <GridActionsCellItem
                            icon={<SaveOutlined />}
                            label="Save"
                            title="Spara"
                            sx={{
                                color: "primary.main",
                            }}
                            onClick={handleSaveClick(id)}
                        />,
                        <GridActionsCellItem icon={<CancelOutlined />} label="Cancel" title="Avbryt" className="textPrimary" onClick={handleCancelClick(id)} color="inherit" />,
                        <GridActionsCellItem icon={<DeleteOutline />} label="Delete" color="error" disabled />,
                    ]
                }

                return [
                    <GridActionsCellItem icon={<EditOutlined />} label="Edit" title="Redigera" className="textPrimary" onClick={handleEditClick(id)} color="inherit" />,
                    <GridActionsCellItem icon={<AddPhotoAlternateOutlined />} label="Add image" title="Lägg till bild" className="textPrimary" onClick={handleAddImageClick(id)} color="inherit" />,
                    <GridActionsCellItem icon={<QueueMusicOutlined />} label="Song package" title="Paket" className="textPrimary" onClick={handleEditSongPackagesClick(id)} color="inherit" />,
                    <GridActionsCellItem icon={<DeleteOutline />} label="Delete" title="Radera" onClick={handleDeleteClick(id)} color="error" />,
                ]
            },
        },
    ]

    return (
        <Stack sx={{ height: "100%", width: "100%", display: "flex", flexDirection: "column" }}>
            <Grid item xs="auto">
                <TextParagraph heading="Låtar och ljud" />
            </Grid>

            <Grid item xs="auto">
                <input type="file" hidden onChange={handleImageUpload} ref={imageUploadRef} />
            </Grid>
            <Grid>
                <EditPackage open={editSongPackagesDialogOpen} closeDialog={closeEditSongPackagesDialog} setRows={setRows} rows={rows} songId={songIdToEdit} />
            </Grid>

            {alert.text ? (
                <Alert
                    severity={alert.severity as AlertColor}
                    onClose={() => {
                        setAlert(initialAlert)
                    }}
                >
                    {alert.text}
                </Alert>
            ) : null}

            <Grid item xs sx={{ overflow: "auto" }}>
                <DataGrid
                    sx={{
                        "& .css-t89xny-MuiDataGrid-columnHeaderTitle": {
                            fontFamily: "Raleway",
                            fontWeight: "bold",
                        },
                    }}
                    rows={rows}
                    columns={columns}
                    editMode="row"
                    initialState={{
                        pagination: {
                            paginationModel: { page: 0, pageSize: 100 },
                        },
                    }}
                    pageSizeOptions={[10, 30, 50, 100]}
                    rowModesModel={rowModesModel}
                    onRowModesModelChange={handleRowModesModelChange}
                    onRowEditStop={handleRowEditStop}
                    processRowUpdate={processRowUpdate}
                    onProcessRowUpdateError={handleProcessRowUpdateError}
                    slots={{
                        toolbar: SongsAndSoundsToolbar,
                    }}
                    slotProps={{
                        toolbar: { setRows, setRowModesModel, rows },
                    }}
                />
            </Grid>
        </Stack>
    )
}

export default SongsAndSoundsTable
