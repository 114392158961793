import { useState, ChangeEvent, FormEvent } from "react"
import { Box, TextField, Alert } from "@mui/material"
import * as Yup from "yup"
import { logIn } from "../firebase/authenticationApi"
import { FirebaseError } from "firebase/app"
import { ROUTES } from "../utils/api"
import { useNavigate } from "react-router-dom"
import BasicButton from "../assets/components/BasicButton"
import RouteButton from "../assets/components/RouteButton"
import { ExitToAppOutlined } from "@mui/icons-material"
import { Logo } from "../assets/components/Logo"
import { Image } from "../assets/components/Image/Image"
import RedInstrument from "../../src/images/red_side_001_small.png"

type AlertColor = "error" | "warning" | "info" | "success"

interface FormData {
    email: string
    password: string
}

const LogInUI = () => {
    const validationSchema: Yup.Schema<FormData> = Yup.object().shape({
        email: Yup.string().email("Ogiltig e-postadress").required("Fyll i din e-postadress"),
        password: Yup.string().required("Fyll i ditt lösenord"),
    })

    const initialFormState: FormData = {
        email: "",
        password: "",
    }

    const initialErrors: FormData = {
        email: "",
        password: "",
    }

    const initialAlert = {
        severity: "",
        text: "",
    }

    const [formData, setFormData] = useState(initialFormState)
    const [errors, setErrors] = useState(initialErrors)
    const [alert, setAlert] = useState(initialAlert)
    const [handlingSubmit, setHandlingSubmit] = useState(false)
    const navigate = useNavigate()

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }

    const handleLogIn = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()

        // Indicate handling submit
        setHandlingSubmit(true)

        // Reset alert
        setAlert(initialAlert)

        try {
            // Validate form data
            await validationSchema.validate(formData, { abortEarly: false })
            setErrors(initialErrors) // Reset errors

            // Log in user
            await logIn(formData.email, formData.password)
        } catch (error: any) {
            if (Yup.ValidationError.isError(error)) {
                // Validation error
                const newErrors: FormData = { ...initialErrors }
                error.inner.forEach((err: any) => {
                    newErrors[err.path as keyof FormData] = err.message
                })
                setErrors(newErrors)

                // Report validation failure
                setAlert({
                    severity: "error",
                    text: "Det finns fel i formuläret nedan",
                })
            } else if (error instanceof FirebaseError) {
                // Firebase error
                console.log("error.code", error.code)
                switch (error.code) {
                    case "auth/invalid-credential":
                        // Report user not found error
                        setAlert({
                            severity: "error",
                            text: "Ogiltig e-postadress eller lösenord",
                        })
                        break
                    default:
                        // Report general Firebase error
                        setAlert({
                            severity: "error",
                            text: "Inloggningen misslyckades...",
                        })
                }
            } else {
                // Other error
                // Report general failure
                setAlert({ severity: "error", text: "Inloggningen misslyckades..." })
            }

            // Indicate done handling submit
            setHandlingSubmit(false)
        }
    }

    return (
        <Box
            sx={{
                borderRadius: {
                    xs: "16px",
                    lg: 0,
                },
                paddingX: {
                    xs: 4,
                    sm: 14,
                    md: 20,
                    lg: 4,
                },
                paddingY: {
                    xs: 12,
                    md: 16,
                    lg: 4,
                },
                width: "100%",
                height: "100%",
                backgroundColor: (theme) => theme.palette.background.default,
            }}
        >
            <Logo size="l" />

            {alert.text ? <Alert severity={alert.severity as AlertColor}>{alert.text}</Alert> : null}

            <form onSubmit={handleLogIn}>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "10px",
                        marginTop: 4,
                    }}
                >
                    <TextField fullWidth label="E-postadress" name="email" value={formData.email} onChange={handleChange} error={!!errors.email} helperText={errors.email} disabled={handlingSubmit} />
                    <TextField
                        fullWidth
                        label="Lösenord"
                        name="password"
                        type="password"
                        value={formData.password}
                        onChange={handleChange}
                        error={!!errors.password}
                        helperText={errors.password}
                        disabled={handlingSubmit}
                    />
                    <BasicButton title="Logga in" type="submit" variant="contained" disabled={handlingSubmit} progress={handlingSubmit} sx={{ marginTop: 2 }} />
                </Box>
            </form>
            <BasicButton
                title="Glömt lösenordet?"
                sx={{ marginTop: 10, color: "gray" }}
                onClick={() => {
                    navigate(ROUTES.REQUEST_PASSWORD_RESET)
                }}
            />
            <Box sx={{ marginTop: "16px" }}>
                <RouteButton title="Skapa nytt konto" route={ROUTES.SIGN_UP} icon={ExitToAppOutlined} />
            </Box>
        </Box>
    )
}

const LogIn = () => {
    return (
        <Box
            sx={{
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: {
                    xs: "center",
                    lg: "flex-start",
                },
                alignItems: { xs: "center", lg: "flex-start" },
            }}
        >
            <Box
                sx={{
                    width: {
                        xs: "95%",
                        lg: "50%",
                    },
                    height: {
                        xs: "95%",
                        lg: "100%",
                    },
                    paddingY: {
                        lg: 12,
                    },
                    paddingX: {
                        lg: 20,
                    },
                    zIndex: {
                        xs: 2,
                    },
                    textAlign: "center",
                }}
            >
                <LogInUI />
            </Box>
            <Box
                sx={{
                    width: {
                        xs: "100%",
                        lg: "50%",
                    },
                    padding: {
                        xs: 0,
                        lg: 1,
                    },
                    zIndex: {
                        xs: 1,
                    },
                    position: {
                        xs: "absolute",
                        lg: "relative",
                    },
                    height: "100%",
                    overflow: "hidden",
                }}
            >
                <Image
                    src={RedInstrument}
                    description="Image of a Funki instrument"
                    sx={{
                        borderRadius: {
                            xs: 0,
                            lg: 4,
                        },
                        width: "150%",
                        transform: "translateX(-30%) scaleX(-1)",
                    }}
                />
            </Box>
        </Box>
    )
}

export default LogIn
