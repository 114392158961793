import { ref, uploadBytes, getDownloadURL, deleteObject } from "firebase/storage";
import { storage } from "./firebaseConfig"

export const uploadSongImage = async (file: File) => {
    return uploadBytes(ref(storage, `app/song-images/${file.name}`), file)
        .then(async (snapshot) => {
            // Upload completed successfully, now we can get the download URL
            const downloadURL = await getDownloadURL(snapshot.ref);
            return downloadURL;
        })
        .catch((error) => {
            // The upload failed
            throw error
        })
}

export const deleteSongImage = async (imageUrl: string) => {
    return deleteObject(ref(storage, getPathStorageFromUrl(imageUrl)))
        .then((snapshot) => {
            // File deleted successfullys
        })
        .catch((error) => {
            // The upload failed
            throw error
        })
}

/**
 * Note: the base url in this function is hardcode and must be updated if it changes.
 * @param url Url to the file in Firebase Storage.
 * @returns 
 */
const getPathStorageFromUrl = (url: String) => {
    // Important! This base url is hardcoded and must be updated for each project
    const baseUrl = "https://firebasestorage.googleapis.com/v0/b/funki-809de.appspot.com/o/";

    // Create the file path from the url
    let imagePath:string = url.replace(baseUrl,"");
    const indexOfEndPath = imagePath.indexOf("?");
    imagePath = imagePath.substring(0,indexOfEndPath);
    imagePath = imagePath.replaceAll("%2F","/");
    
    return imagePath;
}