import { Box, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography } from "@mui/material"
import Text from "../../../assets/components/Text"
import BasicButton from "../../../assets/components/BasicButton"
import { Dispatch, FormEvent, SetStateAction, useEffect, useState } from "react"
import { Customer, Song } from "../../../utils/types"
import { getCustomerDataFromId, getSongPackagesFromCustomer, updateCustomerSongPackages } from "../../../firebase/databaseApi"
import CloseButton from "../../../assets/components/CloseButton"
import { AddOutlined, SaveOutlined } from "@mui/icons-material"

interface Row extends Customer {
    isNew?: boolean
    [key: string]: any;
  }

interface EditSongPackagesDialogProps {
    open: boolean;
    closeDialog: () => void;
    setRows: Dispatch<SetStateAction<Row[]>>;
    rows: Row[];
    customerId: string | null;
}

const EditSongPackagesDialog = (props: EditSongPackagesDialogProps) => {
    const { open, setRows, rows, customerId } = props;
    const [songPackages, setSongPackages] = useState<string[]>([])
    const [newSongPackage, setNewSongPackage] = useState<string>('');
    const [customer, setCustomer] = useState<Customer | undefined>();

    useEffect(() => {
        if (open) {
            // Fetch current song packages for customer
            fetchSongPackagesForCustomer()

            // Fetch customer data from customer id
            fetchCustomerData()
        }
    }, [open])

    const fetchSongPackagesForCustomer = async () => {
        if (customerId) {
            setSongPackages(await getSongPackagesFromCustomer(customerId))
        }
    }

    const fetchCustomerData = async () => {
        if (customerId) {
            setCustomer(await getCustomerDataFromId(customerId))
        }
    }

    const handleAddSongPackage = (event: FormEvent) => {
        event.preventDefault()

        // TODO: add validation, e.g. that a song package exists

        // Add song package
        const newSongPackages = [...songPackages]
        if (newSongPackage) {
            newSongPackages.push(newSongPackage)
        }
        setSongPackages(newSongPackages)
        setNewSongPackage('')
    }

    const handleRemoveSongPackage = (songPackage: string) => {
        const newSongPackages = [...songPackages]
        const index = newSongPackages.indexOf(songPackage);
        if (index > -1) { // only splice array when item is found
            newSongPackages.splice(index, 1); // 2nd parameter means remove one item only
        }
        setSongPackages(newSongPackages)
    }

    const handleSave = async () => {
        try {
            if (!customerId) {
                throw Error("Customer id is undefined.")
            }

            // Save to database
            await updateCustomerSongPackages(customerId, songPackages)

            // Update table visually to reflect change
            const prevRow = rows.find((row) => row.id === customerId);
            const updatedRow: Partial<Row> = { ...prevRow, songPackages: songPackages };
            setRows(rows.map((row) => (
                (row.id === customerId) ? 
                {...row, ...updatedRow} 
                : row
            )));

            // Close dialog
            props.closeDialog()
        } catch (error) {
            // TODO: Do something with error
        }
    }
  
    return (
        <Dialog
            open={open}
            onClose={props.closeDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
            <Text size="heading" bold>
                {`Redigera låtpaket för ${customer?.alias || 'NaN'}`}
            </Text>
            </DialogTitle>
            <DialogContent>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        gap: "10px",
                        width: "100%",
                    }}
                >
                    <form onSubmit={handleAddSongPackage}>
                        <input
                            type="text"
                            value={newSongPackage}
                            onChange={(event) => setNewSongPackage(event.target.value)}
                        />
                        <BasicButton type="submit" title="Lägg till låtpaket" onClick={handleAddSongPackage} icon={AddOutlined} />
                    </form>

                    {songPackages.map((songPackage, index) => (
                        <Box key={index} sx={{ display: "flex", alignItems: "center", width: "100%" }}>
                            <TextField
                                fullWidth
                                id={`songPackage.${index}`}
                                name={`songPackage.${index}`}
                                variant="standard"
                                label="Paketnamn"
                                value={songPackage}
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                            <CloseButton title="Ta bort" onClick={() => handleRemoveSongPackage(songPackage)} disabled={false} />
                        </Box>
                    ))}
                </Box>

            </DialogContent>
            <DialogActions>
                <BasicButton title="Spara" icon={SaveOutlined} onClick={handleSave} />
                <BasicButton title="Avbryt" onClick={props.closeDialog} />
            </DialogActions>
        </Dialog>
    );
}

export default EditSongPackagesDialog