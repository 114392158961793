import { useState, ChangeEvent, FormEvent } from "react"
import { TextField, Alert, Box } from "@mui/material"
import * as Yup from "yup"
import { startPasswordReset } from "../firebase/authenticationApi"
import { FirebaseError } from "firebase/app"
import { ROUTES } from "../utils/api"
import BasicButton from "../assets/components/BasicButton"
import { Logo } from "../assets/components/Logo"
import Text from "../assets/components/Text"
import { ExitToAppOutlined } from "@mui/icons-material"
import RouteButton from "../assets/components/RouteButton"
import { Image } from "../assets/components/Image/Image"
import WhiteInstrument from "../../src/images/white_side_001_small.png"

type AlertColor = "error" | "warning" | "info" | "success"

const RequestPasswordResetUI = () => {
    const emailSchema: Yup.Schema<string> = Yup.string().email("Ogiltig e-postadress").required("Fyll i din e-postadress")

    const initialEmailState = ""
    const initialError = ""
    const initialAlert = {
        severity: "",
        text: "",
    }

    const [email, setEmail] = useState(initialEmailState)
    const [error, setError] = useState(initialError)
    const [alert, setAlert] = useState(initialAlert)
    const [sentEmail, setSentEmail] = useState(false)
    const [handlingSubmit, setHandlingSubmit] = useState(false)

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        setEmail(e.target.value)
    }

    const handleRequestPasswordReset = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()

        // Indicate handling submit
        setHandlingSubmit(true)

        // Reset alert
        setAlert(initialAlert)

        try {
            // Validate form data
            await emailSchema.validate(email, { abortEarly: false })
            setError(initialError) // Reset email error

            // Start password reset
            await startPasswordReset(email)

            // Sent email
            setSentEmail(true)

            // Report success
            setAlert({
                severity: "success",
                text: `Vi har skickat en länk till ${email}. Klicka på länken och följ sedan instruktionerna i det nya fönstret som öppnas. Om inget mail dyker upp, se till att kolla i skräppost-mappen.`,
            })
        } catch (error: any) {
            if (Yup.ValidationError.isError(error)) {
                // Validation error
                setError(error.message)

                // Report validation failure
                setAlert({ severity: "error", text: "Det finns fel i formuläret nedan" })
            } else if (error instanceof FirebaseError) {
                // Firebase error
                // Don't let the user know the details here, pretend that it all went well (do not confirm user account existence)
                console.log("error.code", error.code)

                switch (error.code) {
                    case "auth/user-not-found":
                        // Sent email
                        setSentEmail(true)

                        // Feedback user
                        setAlert({
                            severity: "success",
                            text: `Vi har skickat en länk till ${email}. Klicka på länken och följ sedan instruktionerna i det nya fönstret som öppnas. Om inget mail dyker upp, se till att kolla i skräppost-mappen.`,
                        })
                        console.log("ERROR:", error.message)
                        break
                    default:
                        // Sent email
                        setSentEmail(true)

                        // Feedback user
                        setAlert({
                            severity: "success",
                            text: `Vi har skickat en länk till ${email}. Klicka på länken och följ sedan instruktionerna i det nya fönstret som öppnas. Om inget mail dyker upp, se till att kolla i skräppost-mappen.`,
                        })
                        console.log("ERROR:", error.message)
                }
            } else {
                // Other error
                // Don't let the user know the details here, pretend that it all went well (do not confirm user account existence)

                // Sent email
                setSentEmail(true)

                // Feedback user
                setAlert({
                    severity: "success",
                    text: `Vi har skickat en länk till ${email}. Klicka på länken och följ sedan instruktionerna i det nya fönstret som öppnas. Om inget mail dyker upp, se till att kolla i skräppost-mappen.`,
                })
                console.log("ERROR:", error.message)
            }
        }

        // Indicate done handling submit
        setHandlingSubmit(false)
    }

    return (
        <Box
            sx={{
                borderRadius: {
                    xs: "16px",
                    lg: 0,
                },
                paddingX: {
                    xs: 4,
                    sm: 14,
                    md: 20,
                    lg: 4,
                },
                paddingY: {
                    xs: 12,
                    md: 16,
                    lg: 4,
                },
                width: "100%",
                height: "100%",
                backgroundColor: (theme) => theme.palette.background.default,
            }}
        >
            <Logo size="l" />
            <Box sx={{ paddingBottom: 4 }}>
                <Text size={"giant"}>Glömt ditt lösenord?</Text>
            </Box>
            <Text>Fyll i din e-postadress så skickar vi en länk för att du ska kunna välja ett nytt lösenord.</Text>

            {alert.text ? <Alert severity={alert.severity as AlertColor}>{alert.text}</Alert> : null}

            <form onSubmit={handleRequestPasswordReset}>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "10px",
                        marginTop: 4,
                    }}
                >
                    <TextField fullWidth label="E-postadress" name="email" value={email} onChange={handleChange} error={!!error} helperText={error} disabled={sentEmail || handlingSubmit} />
                    <BasicButton title="Fortsätt" type="submit" variant="contained" disabled={sentEmail || handlingSubmit} progress={handlingSubmit} />
                </Box>
            </form>

            <Box sx={{ marginTop: 10 }}>
                <RouteButton title="Tillbaka till Logga in" route={ROUTES.LOG_IN} icon={ExitToAppOutlined} />
            </Box>
        </Box>
    )
}

const RequestPasswordReset = () => {
    return (
        <Box
            sx={{
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: {
                    xs: "center",
                    lg: "flex-start",
                },
                alignItems: { xs: "center", lg: "flex-start" },
            }}
        >
            <Box
                sx={{
                    width: {
                        xs: "95%",
                        lg: "50%",
                    },
                    height: {
                        xs: "95%",
                        lg: "100%",
                    },
                    paddingY: {
                        lg: 12,
                    },
                    paddingX: {
                        lg: 20,
                    },
                    zIndex: {
                        xs: 2,
                    },
                    textAlign: "center",
                }}
            >
                <RequestPasswordResetUI />
            </Box>
            <Box
                sx={{
                    width: {
                        xs: "100%",
                        lg: "50%",
                    },
                    padding: {
                        xs: 0,
                        lg: 1,
                    },
                    zIndex: {
                        xs: 1,
                    },
                    position: {
                        xs: "absolute",
                        lg: "relative",
                    },
                    height: "100%",
                    overflow: "hidden",
                }}
            >
                <Image
                    src={WhiteInstrument}
                    description="Image of a Funki instrument"
                    sx={{
                        borderRadius: {
                            xs: 0,
                            lg: 4,
                        },
                        width: "150%",
                        transform: "translateX(-30%)",
                    }}
                />
            </Box>
        </Box>
    )
}

export default RequestPasswordReset
