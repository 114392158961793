import logo from "../assets/funki_VAG_1280_red.png"
import { useNavigate } from "react-router-dom"
import { AppBar, Toolbar, Box, Icon } from "@mui/material"
import { ROUTES } from "../utils/api"
import { useIsSmallScreen } from "../hooks/useIsSmallScreen"
import { CurrentUser, useCurrentUser } from "../hooks/contexts/currentUserContext"
import { AdminPanelSettingsOutlined, ArrowBack, LoginRounded, SettingsOutlined, HelpOutline } from "@mui/icons-material"
import RouteButton from "../assets/components/RouteButton"
import Text from "../assets/components/Text"
import { useCurrentLocation } from "../hooks/contexts/currentLocationContext"

const MobileHeader = () => {
    const currentUser = useCurrentUser()
    const currentLocation = useCurrentLocation()
    const navigate = useNavigate()

    const isSettingsPage = currentLocation === "Inställningar"

    return (
        <AppBar position="sticky" sx={{ backgroundColor: "white", boxShadow: "none" }}>
            {isSettingsPage ? (
                <SettingsToolbar currentLocation={currentLocation} navigate={navigate} currentUser={currentUser} />
            ) : (
                <Toolbar
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                    }}
                >
                    <Text noWrap bold size="heading">
                        {currentLocation}
                    </Text>

                    <Box>
                        {currentUser ? (
                            <>
                                {!currentUser.customer && <RouteButton inHeader route={ROUTES.ACTIVATE_ACCOUNT} title="Aktivera konto" />}
                                <RouteButton inHeader route={ROUTES.SETTINGS.MAIN} title="" icon={SettingsOutlined} />
                                {/* {currentUser.admin && <RouteButton inHeader route={ROUTES.ADMIN.MAIN} title="" icon={AdminPanelSettingsOutlined} />} */}
                            </>
                        ) : (
                            <>
                                <RouteButton inHeader route={ROUTES.LOG_IN} title="Logga in" icon={LoginRounded} />
                            </>
                        )}
                    </Box>
                </Toolbar>
            )}
        </AppBar>
    )
}

const SettingsToolbar = ({ currentLocation, navigate, currentUser }: { currentLocation: string; navigate: any; currentUser: CurrentUser }) => {
    return (
        <Toolbar
            sx={{
                display: "flex",
                justifyContent: "space-between",
            }}
        >
            <Box sx={{ display: "flex" }}>
                {currentLocation === "Inställningar" && <ArrowBack onClick={() => navigate(ROUTES.HOME)} sx={{ color: "black", marginRight: "10px", transform: "translate(0%, 2px)" }} />}
                <Text noWrap bold size="heading">
                    {currentLocation}
                </Text>
            </Box>
            <Box>
                {currentUser ? (
                    <>
                        {!currentUser.customer && <RouteButton inHeader route={ROUTES.ACTIVATE_ACCOUNT} title="Aktivera konto" />}
                        {currentUser.admin && <RouteButton inHeader route={ROUTES.ADMIN.MAIN} title="" icon={AdminPanelSettingsOutlined} />}
                    </>
                ) : (
                    <>
                        <RouteButton inHeader route={ROUTES.LOG_IN} title="Logga in" icon={LoginRounded} />
                    </>
                )}
            </Box>
        </Toolbar>
    )
}

export default MobileHeader
