import { ref, child, set, get, push, update } from "firebase/database"
import { database } from "./firebaseConfig"
import { Customer, Instrument, Song, UserData } from "../utils/types"

/**
 * Generates a unique customer id from the database.
 * @returns A unique customer id.
 */
export const generateNewCustomerId = () => {
    // Get id (key) for new song
    const newCustomerId = push(child(ref(database), "customers/")).key
    return newCustomerId
}

export const addNewUser = (userId: string, email: string, name: string | null, createdDate: string | null, connectedCustomerId: string = "") => {
    set(ref(database, `users/${userId}`), {
        email: email,
        name: name,
        created: createdDate,
        connectedCustomerId: connectedCustomerId,
    })
        .then(() => {
            // Data saved successfully
        })
        .catch((error) => {
            // The write failed
            throw error
        })
}

export const updateCustomerForUser = async (userUid: string | undefined, connectedCustomerId: string) => {
    if (!userUid) {
        throw new Error("User UID is undefined")
    }

    // Prepare update
    const updates: Record<string, any> = {}
    updates[`users/${userUid}/connectedCustomerId`] = connectedCustomerId

    return update(ref(database), updates)
        .then(() => {
            // Data saved successfully
        })
        .catch((error) => {
            // The write failed
            console.log("Failed to set customer for user in database", error)
            throw error
        })
}

export const validActivationCode = async (activationCode: string) => {
    return get(child(ref(database), `customers/${activationCode}`))
        .then((snapshot) => {
            if (snapshot.exists()) {
                console.log("valid code")
                return true
            } else {
                console.log("INvalid code")
                return false
            }
        })
        .catch((error) => {
            console.error(error)
            throw error
        })
}

export const getCustomerInstruments = async (customerId: string) => {
    return get(child(ref(database), `customerData/${customerId}/instruments`))
        .then((snapshot) => {
            if (snapshot.exists()) {
                const instruments: Instrument[] = Object.values(snapshot.val())
                return instruments
            } else {
                return []
            }
        })
        .catch((error) => {
            throw error
        })
}

export const getAllCustomerEntities = () => {
    get(child(ref(database), `customers`))
        .then((snapshot) => {
            if (snapshot.exists()) {
                console.log("Successfully fetched all customer entities from database:", snapshot.val())
            } else {
                console.log("No data available")
            }
        })
        .catch((error) => {
            console.error(error)
        })
}

/**
 * Gets customer alias for the customer with the given customer id.
 * @customerId The customer id.
 * @returns The customer alias.
 */
export const getCustomerAlias = async (customerId: string) => {
    return get(child(ref(database), `customerData/${customerId}/alias`))
        .then((snapshot) => {
            if (snapshot.exists()) {
                return snapshot.val()
            } else {
                return null
            }
        })
        .catch((error) => {
            throw error
        })
}

export const getCustomerIdFromUser = async (userId: string) => {
    // Get customer id associated to user
    return get(child(ref(database), `users/${userId}/connectedCustomerId`))
        .then((snapshot) => {
            if (snapshot.exists()) {
                return snapshot.val()
            } else {
                return ""
            }
        })
        .catch((error) => {
            console.error(error)
            throw error
        })
}

export const getCustomerDataFromUser = async (userId: string) => {
    // Get customer id associated to user
    const customerId = await getCustomerIdFromUser(userId)

    if (customerId) {
        // Get data associated to customer
        return get(child(ref(database), `customerData/${customerId}`))
            .then((snapshot) => {
                if (snapshot.exists()) {
                    const customerData = {
                        ...snapshot.val(),
                        songPackages: snapshot.val().songPackages ? Object.keys(snapshot.val().songPackages) : [],
                        id: customerId,
                    }
                    return customerData
                } else {
                    return null
                }
            })
            .catch((error) => {
                console.error(error)
                throw error
            })
    } else {
        return null
    }
}

export const getCustomerDataFromId = async (customerId: string) => {
    if (customerId) {
        // Get data associated to customer
        return get(child(ref(database), `customerData/${customerId}`))
            .then((snapshot) => {
                if (snapshot.exists()) {
                    const customerData = {
                        ...snapshot.val(),
                        songPackages: snapshot.val().songPackages ? Object.keys(snapshot.val().songPackages) : [],
                        id: customerId,
                    }
                    return customerData
                } else {
                    return null
                }
            })
            .catch((error) => {
                console.error(error)
                throw error
            })
    } else {
        return null
    }
}

export const getAllSongsForCustomer = async (customerId: string) => {
    if (customerId) {
        // Get song packages
        const songPackages = await getSongPackagesFromCustomer(customerId)

        // Return null if no song packages were found
        if (!songPackages || songPackages.length < 1) {
            return []
        }

        // Get song IDs for all song packages
        const allSongIds: Set<string> = new Set()
        await Promise.all(
            songPackages.map(async (songPackage) => {
                const songIds = await getSongsFromSongPackage(songPackage)
                if (songIds) {
                    songIds.forEach((songId) => allSongIds.add(songId))
                }
            })
        )
        const uniqueSongIdsArray = Array.from(allSongIds)

        // Get song data for all song IDs
        const allSongsWithData: Song[] = []
        await Promise.all(
            uniqueSongIdsArray.map(async (songId) => {
                const songData = await getSongData(songId)
                allSongsWithData.push({ ...songData, id: songId })
            })
        )

        // Return list with all song data
        return allSongsWithData
    } else {
        return []
    }
}

export const getSongPackagesFromCustomer = async (customerId: string) => {
    if (customerId) {
        return get(child(ref(database), `customerData/${customerId}/songPackages`))
            .then((snapshot) => {
                if (snapshot.exists()) {
                    return snapshot.val() ? Object.keys(snapshot.val()) : []
                } else {
                    return []
                }
            })
            .catch((error) => {
                console.error(error)
                throw error
            })
    } else {
        return []
    }
}

export const getSongsFromSongPackage = async (songPackage: string) => {
    if (songPackage) {
        return get(child(ref(database), `songPackages/${songPackage}/songs`))
            .then((snapshot) => {
                if (snapshot.exists()) {
                    return Object.keys(snapshot.val())
                } else {
                    return null
                }
            })
            .catch((error) => {
                console.error(error)
                throw error
            })
    } else {
        return null
    }
}

export const getSongData = async (songId: string | null) => {
    if (songId) {
        return get(child(ref(database), `songData/${songId}`))
            .then((snapshot) => {
                if (snapshot.exists()) {
                    return snapshot.val()
                } else {
                    return null
                }
            })
            .catch((error) => {
                console.error(error)
                throw error
            })
    } else {
        return null
    }
}

export const getAllSongData = async () => {
    return get(child(ref(database), `songData`))
        .then((snapshot) => {
            if (snapshot.exists()) {
                const data = snapshot.val()
                // Return a list of song objects
                const songs: Song[] = Object.keys(data).map((id) => {
                    return { ...data[id], id: id }
                })
                return songs
            } else {
                return []
            }
        })
        .catch((error) => {
            console.error(error)
            throw error
        })
}

export const getSongPackagesFromSong = async (songId: string) => {
    if (songId) {
        return get(child(ref(database), `songData/${songId}/songPackages`))
            .then((snapshot) => {
                if (snapshot.exists()) {
                    return snapshot.val() ? Object.keys(snapshot.val()) : []
                } else {
                    return []
                }
            })
            .catch((error) => {
                console.error(error)
                throw error
            })
    } else {
        return []
    }
}

/**
 * Generates a unique song id from the database.
 * @returns A unique song id.
 */
export const generateNewSongId = () => {
    // Get id (key) for new song
    const newSongId = push(child(ref(database), "songData/")).key
    return newSongId
}

/**
 * Removes a song from the database. Also handles dependent actions,
 * such as removing the song id from its song packages song list in
 * the database (if it has a song package).
 * @param songId The id of the song to remove.
 * @param songPackage The song package the song belongs to. Use undefined if
 * the song does not belong to any song package.
 * @returns
 */
export const deleteSong = async (songId: string, songPackage: string | undefined) => {
    // Prepare updates
    const updates: Record<string, any> = {}
    updates[`songData/${songId}`] = null // setting value to null removes entry

    // Remove from song package list if applicable
    if (songPackage) {
        // Setting value to null removes entry
        updates[`songPackages/${songPackage}/songs/${songId}`] = null
    }

    return update(ref(database), updates)
        .then(() => {
            // Data removed successfully
        })
        .catch((error) => {
            // The updates failed
            throw error
        })
}

const validateSong = (song: Song) => {
    if (!song.id) {
        throw Error("A song must have an id.")
    }
    // TODO: Add more thorough client-side validation
    // ...
}

/**
 * Updates the whole data object for the given song. Also handles dependent actions,
 * such as removing the old song object if the id is changed and updates the song package
 * node in the database to reflect the song update (if song package field is changed or
 * song id is changed).
 * @param song The song object.
 * @param prevId The previous song id (before the change). Use undefined if the song
 * did not have any id previously (e.g. if it is a new song).
 * @param prevSongPackage The previous song package for the song (before the change).
 * Use undefined if the song did not belong to any song package previously.
 * @returns
 */
export const updateSongData = async (song: Song, prevId: string | undefined, prevSongPackage: string | undefined) => {
    // Validate song
    validateSong(song)

    // Seperate the song id from the rest of the data
    const { id, ...data } = Object.assign({}, song)

    // Prepare updates
    const updates: Record<string, any> = {}

    // Song data update
    updates[`songData/${id}`] = data

    // If song id is to be updated, remove old entry
    if (prevId && id !== prevId) {
        updates[`songData/${prevId}`] = null // null as value will remove the entry
    }
    // Reflect changes of song package or song id change in song packages lists
    // if the song to be added is not a new song (prevId != null)
    if (prevId && (song.songPackages !== prevSongPackage || song.id !== prevId)) {
        if (song.songPackages) {
            // Put song id in song list for new song package in database
            updates[`songPackages/${song.songPackages}/songs/${song.id}`] = true
        }

        if (prevSongPackage) {
            // If it previously belonged to other song package, remove this
            // song id from that song package's node in database
            updates[`songPackages/${prevSongPackage}/songs/${prevId}`] = null
        }
    }

    return update(ref(database), updates)
        .then(() => {
            // Data saved successfully
        })
        .catch((error) => {
            // The write failed
            console.log("Failed to update song entry in database", error)
            throw error
        })
}

export const updateSongPackages = async (songId: string, songPackages: string[]) => {
    type SongPackageObj = {
        [key: string]: any
    }

    // Prepare updates
    const updates: Record<string, any> = {}

    // Make list of song package IDs to list of objects to fit database
    const songPackageObjects =
        songPackages.reduce((acc: SongPackageObj, songPackage: string) => {
            acc[songPackage] = true
            updates[`songPackages/${songPackage}/songs/${songId}`] = true
            return acc
        }, {}) || null

    updates[`songData/${songId}/songPackages`] = songPackageObjects

    return update(ref(database), updates)
        .then(() => {
            // Data saved successfully
        })
        .catch((error) => {
            // The update failed
            throw error
        })
}

/**
 * Updates the image url for song with the given id in the database.
 * @param songId The id of the song for which the image url is to be updated.
 * @param imageUrl The url for the image.
 * @returns
 */
export const updateSongImage = async (songId: string, imageUrl: string) => {
    // Prepare updates
    const updates: Record<string, any> = {}
    updates[`songData/${songId}/img`] = imageUrl

    return update(ref(database), updates)
        .then(() => {
            // Data saved successfully
        })
        .catch((error) => {
            // The write failed
            throw error
        })
}

/**
 * Gets all customer data objects in the database.
 * @returns List of customer objects.
 */
export const getAllCustomerData = async () => {
    return get(child(ref(database), `customerData`))
        .then((snapshot) => {
            if (snapshot.exists()) {
                const data = snapshot.val()
                // Return a list of customer objects
                const customers: Customer[] = Object.keys(data).map((id) => {
                    return {
                        ...data[id],
                        id: id,
                        songPackages: data[id].songPackages ? Object.keys(data[id].songPackages) : [],
                    }
                })
                return customers
            } else {
                return []
            }
        })
        .catch((error) => {
            throw error
        })
}

/**
 * Updates the whole data object for the given customer.
 * @param customer The customer object.
 * @returns
 */
export const updateCustomerData = async (customer: Customer, isNew: boolean | undefined) => {
    // Seperate the customer id from the rest of the data
    const { id, ...data } = Object.assign({}, customer)

    type SongPackageObj = {
        [key: string]: any
    }

    // Make list of song package IDs to list of objects to fit database
    const songPackageObjects =
        data.songPackages?.reduce((acc: SongPackageObj, songPackage: string) => {
            acc[songPackage] = true
            return acc
        }, {}) || null

    // Prepare updates
    const updates: Record<string, any> = {}

    // Replace song packages with assembled song package objects
    updates[`customerData/${id}`] = { ...data, songPackages: songPackageObjects }
    if (isNew) {
        updates[`customers/${id}`] = true
    }

    return update(ref(database), updates)
        .then(() => {
            // Data saved successfully
        })
        .catch((error) => {
            // The update failed
            throw error
        })
}

export const updateCustomerSongPackages = async (customerId: string, songPackages: string[]) => {
    type SongPackageObj = {
        [key: string]: any
    }

    // Make list of song package IDs to list of objects to fit database
    const songPackageObjects =
        songPackages.reduce((acc: SongPackageObj, songPackage: string) => {
            acc[songPackage] = true
            return acc
        }, {}) || null

    // Prepare updates
    const updates: Record<string, any> = {}
    updates[`customerData/${customerId}/songPackages`] = songPackageObjects

    return update(ref(database), updates)
        .then(() => {
            // Data saved successfully
        })
        .catch((error) => {
            // The update failed
            throw error
        })
}

export const updateCustomerInstruments = async (customerId: string, instruments: Instrument[]) => {
    // Prepare updates
    const updates: Record<string, any> = {}
    updates[`customerData/${customerId}/instruments`] = instruments

    return update(ref(database), updates)
        .then(() => {
            // Data saved successfully
        })
        .catch((error) => {
            // The update failed
            throw error
        })
}

/**
 * Removes a customer from the database.
 * @returns
 */
export const deleteCustomer = async (customerId: string) => {
    // Prepare updates
    const updates: Record<string, any> = {}
    updates[`customerData/${customerId}`] = null // setting value to null removes entry
    updates[`customers/${customerId}`] = null // setting value to null removes entry

    return update(ref(database), updates)
        .then(() => {
            // Data removed successfully
        })
        .catch((error) => {
            // The updates failed
            throw error
        })
}

/**
 * Gets all user data from the database and auth.
 * @returns List of user objects.
 */
export const getAllUserData = async () => {
    return get(child(ref(database), `users`))
        .then((snapshot) => {
            if (snapshot.exists()) {
                const data = snapshot.val()
                // Return a list of user objects
                const users: UserData[] = Object.keys(data).map((id) => {
                    return {
                        ...data[id],
                        id: id,
                    }
                })
                return users
            } else {
                return []
            }
        })
        .catch((error) => {
            throw error
        })
}

/**
 * Updates the whole data object for the given customer from the database.
 * @param customer The customer object.
 * @returns
 */
export const updateUserData = async (user: UserData) => {
    // Seperate the customer id from the rest of the data
    const { id, ...data } = Object.assign({}, user)

    // Prepare updates
    const updates: Record<string, any> = {}
    updates[`users/${id}`] = data

    return update(ref(database), updates)
        .then(() => {
            // Data saved successfully
        })
        .catch((error) => {
            // The update failed
            throw error
        })
}

export const userIsAdmin = async (userId: string) => {
    return get(child(ref(database), `users/${userId}/isAdmin`))
        .then((snapshot) => {
            if (snapshot.exists()) {
                return snapshot.val()
            } else {
                return false
            }
        })
        .catch((error) => {
            console.error(error)
            throw error
        })
}
