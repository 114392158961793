import { Grid } from "@mui/material"
import SongsAndSoundsTable from "./components/SongsAndSoundsTable"

const SoundbanksAndSongs = () => {

    return (
        <Grid sx={{ height: "100%" }}>
            <SongsAndSoundsTable />
        </Grid>
    )
}

export default SoundbanksAndSongs
