import { Box, Grid } from "@mui/material"
import CustomersTable from "./components/CustomersTable"

const Customers = () => {

    return (
        <Grid sx={{ height: "100%" }}>
            <CustomersTable />
        </Grid>
    )
}

export default Customers
