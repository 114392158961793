import { Box, Button, Divider, Slider } from "@mui/material"
import PlayArrowIcon from "@mui/icons-material/PlayArrow"
import PauseIcon from "@mui/icons-material/Pause"
import VolumeUpIcon from "@mui/icons-material/VolumeUp"
import { useAudioPlayer } from "../../hooks/contexts/audioPlayerContext"
import { BackingTrack } from "../../utils/types"
import Text from "../../assets/components/Text"

interface AudioPlayerProps {
    backingTrack: BackingTrack
    isSmallScreen?: boolean
}

const AudioPlayer = ({ backingTrack, isSmallScreen = false }: AudioPlayerProps) => {
    const { isPlaying, togglePlay, currentTime, duration, volume, setVolume, progress, handleProgressChange, setCurrentTrack } = useAudioPlayer()

    // Set the current track in the global context
    setCurrentTrack(backingTrack)

    const formatTime = (time: number) => {
        const minutes = Math.floor(time / 60)
        const seconds = Math.floor(time % 60)
        return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`
    }

    return (
        <Box
            sx={{
                paddingY: 1,
                gap: 1,
                display: "flex",
                width: "100%",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            <Box sx={{ marginLeft: "100px", width: "100%" }}>
                <Divider orientation="horizontal" sx={{ borderColor: "#808080", width: "100%" }} />
            </Box>
            <Box
                sx={{
                    display: "grid",
                    gridTemplateColumns: isSmallScreen ? "1fr" : "1fr 1fr 1fr",
                    gridTemplateRows: isSmallScreen ? "auto" : "none",
                    justifyItems: isSmallScreen ? "center" : "none",
                }}
            >
                <Box></Box>
                <Box sx={{ display: "flex", flexDirection: isSmallScreen ? "column" : "row", alignItems: "center", columnGap: "2rem" }}>
                    <Button onClick={togglePlay} variant="contained" size="small" sx={{ padding: "2px", width: "10%", borderRadius: "50px" }}>
                        {isPlaying ? <PauseIcon /> : <PlayArrowIcon />}
                    </Button>
                    <Slider value={progress} onChange={handleProgressChange} sx={{ width: isSmallScreen ? 300 : 400 }} />
                    <Box sx={{ minWidth: "120px", textAlign: "center" }}>
                        {formatTime(currentTime)} / {formatTime(duration)}
                    </Box>
                </Box>
                <Box sx={{ display: "flex", gap: 1, justifyContent: "right", alignItems: "center" }}>
                    <VolumeUpIcon />
                    <Slider value={volume} onChange={(e, value) => setVolume(value as number)} sx={{ width: 100 }} />
                </Box>
            </Box>
        </Box>
    )
}

export default AudioPlayer
