import { createTheme } from "@mui/material"

export const theme = createTheme({
    palette: {
        primary: {
            main: "#d8674e",
        },
        secondary: {
            main: "#f50057",
        },
        success: {
            main: "#9bd64d",
        },
    },
    typography: {
        button: {
            textTransform: "none",
        },
    },
})
