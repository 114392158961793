import { useState, ChangeEvent, FocusEvent, FormEvent } from "react"
import { Box, TextField, Alert } from "@mui/material"
import * as Yup from "yup"
import { FirebaseError } from "firebase/app"
import BasicButton from "../assets/components/BasicButton"
import { useCurrentUser } from "../hooks/contexts/currentUserContext"
import { changePassword } from "../firebase/authenticationApi"
import { User } from "firebase/auth"

interface FormData {
    curPassword: string
    newPassword: string
}

type AlertColor = "error" | "warning" | "info" | "success"

const validationSchema: Yup.Schema<FormData> = Yup.object().shape({
    curPassword: Yup.string().required("Obligatorisk").min(8, "Måste vara minst 8 karaktärer"),
    newPassword: Yup.string().required("Obligatorisk").min(8, "Måste vara minst 8 karaktärer"),
})

const ChangePassword = () => {
    const initialFormState: FormData = {
        curPassword: "",
        newPassword: "",
    }

    const initialErrors: FormData = {
        curPassword: "",
        newPassword: "",
    }

    const initialAlert = {
        severity: "",
        text: "",
    }

    const currentUser = useCurrentUser()
    const [formData, setFormData] = useState(initialFormState)
    const [errors, setErrors] = useState(initialErrors)
    const [alert, setAlert] = useState(initialAlert)
    const [handlingSubmit, setHandlingSubmit] = useState(false)

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }

    const handleBlur = async (e: FocusEvent<HTMLInputElement>) => {
        const name = e.target.name
        const value = e.target.value

        try {
            await (validationSchema as Yup.ObjectSchema<FormData>).validateAt(name, { [name]: value })
            setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }))
        } catch (error: any) {
            if (Yup.ValidationError.isError(error)) {
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    [name]: error.errors[0],
                }))
            }
        }
    }

    const handleChangePassword = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()

        // Indicate handling submit
        setHandlingSubmit(true)

        // Reset alert
        setAlert(initialAlert)

        try {
            // Validate form data
            await validationSchema.validate(formData, { abortEarly: false })
            setErrors(initialErrors) // Reset errors

            // Change password
            await changePassword(currentUser?.auth as User, formData.curPassword, formData.newPassword)

            // Report success
            setAlert({ severity: "success", text: "Lösenordet har ändrats" })
            setFormData(initialFormState) // Reset form
        } catch (error: any) {
            if (Yup.ValidationError.isError(error)) {
                // Validation error
                const newErrors: FormData = { ...initialErrors }
                error.inner.forEach((err: any) => {
                    newErrors[err.path as keyof FormData] = err.message
                })
                setErrors(newErrors)

                // Report validation failure
                setAlert({ severity: "error", text: "Det finns fel i formuläret nedan" })
            } else if (error instanceof FirebaseError) {
                // Firebase error
                switch (error.code) {
                    case "user-not-found":
                    case "invalid-email":
                    case "auth/invalid-login-credentials":
                    case "auth/wrong-password":
                        // Report user/password error
                        setAlert({ severity: "error", text: "Angivna nuvarande lösenordet är felaktigt" })
                        setErrors({ ...errors, curPassword: "Ogiltigt lösenord" })
                        break
                    case "auth/weak-password":
                        // Report weak password error
                        setAlert({ severity: "error", text: "Lösenordet är för svagt eller vanligt, välj ett annat nytt lösenord" })
                        break
                    default:
                        // Report general Firebase error
                        setAlert({ severity: "error", text: "Misslyckades att ändra lösenordet..." })
                }
            } else {
                // Other error
                // Report general failure
                setAlert({ severity: "error", text: "Misslyckades att ändra lösenordet..." })
            }
        }

        // Indicate done handling submit
        setHandlingSubmit(false)
    }

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                width: "100%",
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    gap: "10px",
                    width: "100%",
                }}
            >
                {alert.text ? (
                    <Alert
                        severity={alert.severity as AlertColor}
                        onClose={() => {
                            setAlert(initialAlert)
                        }}
                    >
                        {alert.text}
                    </Alert>
                ) : null}

                <form onSubmit={handleChangePassword}>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                            gap: "10px",
                            width: "100%",
                        }}
                    >
                        <TextField
                            label="Nuvarande lösenord"
                            name="curPassword"
                            type="password"
                            variant="standard"
                            value={formData.curPassword}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={!!errors.curPassword}
                            helperText={errors.curPassword}
                            disabled={handlingSubmit}
                        />
                        <TextField
                            label="Nytt lösenord"
                            name="newPassword"
                            type="password"
                            variant="standard"
                            value={formData.newPassword}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={!!errors.newPassword}
                            helperText={errors.newPassword}
                            disabled={handlingSubmit}
                        />
                        <BasicButton title="Ändra lösenord" type="submit" disabled={handlingSubmit} progress={handlingSubmit} />
                    </Box>
                </form>
            </Box>
        </Box>
    )
}

export default ChangePassword
