import { MouseEventHandler } from "react"
import { IconButton, SvgIcon } from "@mui/material"

interface CloseButtonProps {
    onClick: MouseEventHandler<HTMLButtonElement>
    title: string
    disabled: boolean
}

const CloseButton = ({ onClick, title, disabled }: CloseButtonProps) => {
    return (
        <IconButton
            onClick={onClick}
            aria-label={title}
            title={title}
            disabled={disabled}
            sx={{
                height: "30px",
                width: "30px",
            }}
        >
            <SvgIcon sx={{ fontSize: "20px" }}>
                <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
            </SvgIcon>
        </IconButton>
    )
}

export default CloseButton
