import "./App.css"
import { BrowserRouter as Router } from "react-router-dom"
import { CurrentUserProvider } from "./hooks/contexts/currentUserContext"
import { CurrentSongProvider } from "./hooks/contexts/currentSongContext"
import { MqttHandlerProvider } from "./mqtt/mqttHandlerContext"
import { CurrentLocationProvider } from "./hooks/contexts/currentLocationContext"
import { ThemeProvider } from "@mui/material"
import { theme } from "./theme"
import ContentsLoggedIn from "./components/ContentsLoggedIn"
import ContentsNoUser from "./components/ContentsNoUser"
import Loading from "./pages/Loading"
import { AudioPlayerProvider } from "./hooks/contexts/audioPlayerContext"
import { useCurrentUser } from "./hooks/contexts/currentUserContext"
import { ConnectedInstrumentsProvider } from "./hooks/contexts/connectedInstrumentsContext"

const AppContents = () => {
    const currentUser = useCurrentUser()

    return (
        <>
            {currentUser !== undefined ? (
                <>
                    {currentUser ? (
                        <ConnectedInstrumentsProvider>
                            <MqttHandlerProvider>
                                <CurrentSongProvider>
                                    <CurrentLocationProvider>
                                        <AudioPlayerProvider>
                                            <ContentsLoggedIn />
                                        </AudioPlayerProvider>
                                    </CurrentLocationProvider>
                                </CurrentSongProvider>
                            </MqttHandlerProvider>
                        </ConnectedInstrumentsProvider>
                    ) : (
                        <ContentsNoUser />
                    )}
                </>
            ) : (
                <Loading />
            )}
        </>
    )
}

function App() {
    return (
        <Router>
            <ThemeProvider theme={theme}>
                <CurrentUserProvider>
                    <AppContents />
                </CurrentUserProvider>
            </ThemeProvider>
        </Router>
    )
}

export default App
