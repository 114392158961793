import { Box, LinearProgress } from "@mui/material"
import logo from "../assets/funki_VAG_1280_red.png"

const Loading = () => {
    return (
        <Box
            sx={{
                paddingTop: 5,
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                height: "100%",
                width: "300px",
                margin: "0 auto",
                padding: "16px",
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    margin: "30px",
                }}
            >
                <img src={logo} alt="Funki logo" />
            </Box>
            <LinearProgress color="inherit" />
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    margin: "0 auto",
                    marginTop: "20px",
                    fontWeight: "bold",
                }}
            >
                <p>Laddar...</p>
            </Box>
        </Box>
    )
}

export default Loading
