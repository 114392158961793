export const toggleButtonGroupStyling = {
    backgroundColor: "#f5f5f5",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
    display: "flex",
    flexWrap: "wrap",
}

export const toggleButtonStyling = {
    color: "#555",
    backgroundColor: "#fff",
    borderRadius: "5px",
    margin: "0 0px",
    textTransform: "none",
    fontSize: "14px",
    fontWeight: "500",
    padding: "16px 24px",
    "&.Mui-selected": {
        backgroundColor: (theme: any) => theme.palette.primary.main,
        color: "#fff",
    },
    "&:hover": {
        backgroundColor: "#e0e0e0",
    },
}
