import { createContext, useState, ReactNode, useEffect, useContext, Dispatch, SetStateAction, useCallback } from "react"
import { Instrument, InstrumentType, Song, SONG_TYPES, TouchMode } from "../../utils/types"
import { useBeforeUnload } from "react-router-dom"
import { useMqttIsConnected, usePostMqttChangeSong, usePostSetInstrumentMode } from "../../mqtt/mqttHandlerContext"
import { useCurrentUser } from "./currentUserContext"
import { userSongList } from "../../utils/songList"
import { useConnectedInstruments, useUpdateConnectedInstrument } from "./connectedInstrumentsContext"
import { buttonSounds, joystickSounds, touchSounds } from "../../utils/api"

export type ChangeCurrentSong = ({ songId, songType, instrumentSound, deviceId }: { songId?: string; songType?: SONG_TYPES; instrumentSound?: string; deviceId?: string }) => void

interface CurrentSongContextProps {
    currentSong: Song | undefined
    changeCurrentSong: ChangeCurrentSong
    currentlyPlayingIsExpanded: boolean
    setCurrentlyPlayingIsExpanded: Dispatch<SetStateAction<boolean>>
    changeInstrumentMode: any
}

const CurrentSongContext = createContext<CurrentSongContextProps>({
    currentSong: undefined,
    changeCurrentSong: () => {},
    currentlyPlayingIsExpanded: true,
    setCurrentlyPlayingIsExpanded: () => {},
    changeInstrumentMode: () => {},
})

const saveToSessionStorage = (song: string) => {
    // Save the current song in session storage
    sessionStorage.setItem("currentSong", song as string)
}

// const readLastCurrentSong = () => {
//     // Read the current song from session storage
//     return sessionStorage.getItem("currentSong")
// }

export const CurrentSongProvider = ({ children }: { children: ReactNode }) => {
    const [currentSong, setCurrentSong] = useState<Song | undefined>(undefined)
    const [currentlyPlayingIsExpanded, setCurrentlyPlayingIsExpanded] = useState<boolean>(true)
    const mqttIsConnected = useMqttIsConnected()
    const postMqttChangeSong = usePostMqttChangeSong()
    const currentUser = useCurrentUser()
    const connectedInstruments = useConnectedInstruments()
    const updateConnectedInstruments = useUpdateConnectedInstrument()
    const postSetInstrumentMode = usePostSetInstrumentMode()

    function getDefaultSound(type: InstrumentType) {
        if (type === "joysticks") {
            return joystickSounds[0].id
        }
        if (type === "buttons") {
            return buttonSounds[0].id
        }
        if (type === "touch") {
            return touchSounds[0].id
        }
    }

    const songList = currentUser?.songList

    const changeCurrentSong = ({ songId, songType, instrumentSound, deviceId }: { songId?: string; songType?: SONG_TYPES; instrumentSound?: string; deviceId?: string }) => {
        const songObject = songList?.find((songObj) => songObj.id === songId)

        if (!deviceId) {
            connectedInstruments.forEach((instrument: Instrument) => {
                updateConnectedInstruments({
                    deviceId: instrument.deviceId,
                    serialNr: instrument.serialNr,
                    type: instrument.type,
                    color: instrument.color,
                    touchMode: instrument.type === "touch" || instrument.type === "hybrid" ? songObject?.touchMode : undefined,
                    currentSong: {
                        ...instrument.currentSong,
                        category: songObject?.category.toLowerCase(),
                        key: songObject?.keytone,
                        songType: songObject?.type,
                        id: songObject?.id,
                        sound: songType === SONG_TYPES.KEY ? getDefaultSound(instrument.type) : undefined,
                        touchMode: songObject?.touchMode,
                    },
                })
            })
            setCurrentSong(songObject)

            if (songType === SONG_TYPES.KEY) {
                connectedInstruments.forEach((instrument: Instrument) => {
                    postMqttChangeSong({ message: songObject?.keytone + "-" + songObject?.category + "-" + getDefaultSound(instrument.type), deviceId: instrument.deviceId })
                })
            } else if (songObject) {
                connectedInstruments.forEach((instrument: Instrument) => {
                    postMqttChangeSong({ message: songObject?.id, deviceId: instrument.deviceId })
                })
            }

            if (songObject?.touchMode === "EASY") {
                postSetInstrumentMode({ touchMode: "EASY" })
            }
            if (songObject?.touchMode === "BOTH" || songObject?.touchMode === "HARD") {
                postSetInstrumentMode({ touchMode: "HARD" })
            }
        }

        if (songType === SONG_TYPES.KEY && instrumentSound && deviceId) {
            const instrumentToUpdate = connectedInstruments.find((instrument: Instrument) => instrument.deviceId === deviceId)

            if (instrumentToUpdate) {
                updateConnectedInstruments({
                    ...instrumentToUpdate,
                    currentSong: {
                        ...instrumentToUpdate.currentSong,
                        sound: instrumentSound,
                    },
                })
                postMqttChangeSong({ message: instrumentToUpdate?.currentSong?.key + "-" + instrumentToUpdate?.currentSong?.category + "-" + instrumentSound, deviceId: deviceId })
            }
        } else if (deviceId && songId) {
            const instrumentToUpdate = connectedInstruments.find((instrument: Instrument) => instrument.deviceId === deviceId)

            if (instrumentToUpdate) {
                updateConnectedInstruments({
                    ...instrumentToUpdate,
                    currentSong: {
                        ...instrumentToUpdate.currentSong,
                        category: songObject?.category.toLowerCase(),
                        key: songObject?.keytone,
                        songType: songObject?.type,
                        id: songObject?.id,
                        sound: songObject?.type === SONG_TYPES.KEY ? getDefaultSound(instrumentToUpdate.type) : undefined,
                    },
                })
                if (songObject?.type === SONG_TYPES.KEY) {
                    postMqttChangeSong({ message: songObject?.keytone + "-" + songObject?.category + "-" + getDefaultSound(instrumentToUpdate.type), deviceId: instrumentToUpdate.deviceId })
                } else {
                    postMqttChangeSong({ message: songId, deviceId: deviceId })
                }
            }
        }
    }

    const changeInstrumentMode = ({ deviceId, touchMode }: { deviceId: string; touchMode: TouchMode }) => {
        const instrumentToUpdate = connectedInstruments.find((instrument: Instrument) => instrument.deviceId === deviceId)

        if (instrumentToUpdate) {
            updateConnectedInstruments({
                color: instrumentToUpdate.color,
                deviceId: instrumentToUpdate.deviceId,
                serialNr: instrumentToUpdate.serialNr,
                type: instrumentToUpdate.type,
                touchMode: touchMode,
                currentSong: {
                    ...instrumentToUpdate.currentSong,
                },
            })

            postSetInstrumentMode({ deviceId: deviceId, touchMode: touchMode })
        }
    }

    // useEffect(() => {
    //     // Read current song from last session
    //     const lastSongId = readLastCurrentSong()
    //     if (lastSongId) {
    //         // Get song details
    //         const songObject = songList?.find((songObj) => songObj.id === lastSongId)

    //         // Set current song
    //         setCurrentSong(songObject)
    //     }
    // }, [])

    // useEffect(() => {
    //     // Message change to instruments
    //     if (currentSong && mqttIsConnected) {
    //         postMqttChangeSong({ message: currentSong.id })
    //     }
    // }, [currentSong, mqttIsConnected])

    // Save to sesssion storage
    useBeforeUnload(
        useCallback(() => {
            saveToSessionStorage(currentSong?.id as string)
        }, [currentSong])
    )

    return (
        <CurrentSongContext.Provider value={{ currentSong, changeCurrentSong, currentlyPlayingIsExpanded, setCurrentlyPlayingIsExpanded, changeInstrumentMode }}>
            {children}
        </CurrentSongContext.Provider>
    )
}

export const useCurrentSong = () => useContext(CurrentSongContext)?.currentSong
export const useChangeCurrentSong = () => useContext(CurrentSongContext)?.changeCurrentSong
export const useCurrentlyPlayingIsExpanded = () => useContext(CurrentSongContext)?.currentlyPlayingIsExpanded
export const useSetCurrentlyPlayingIsExpanded = () => useContext(CurrentSongContext)?.setCurrentlyPlayingIsExpanded
export const useSetInstrumentMode = () => useContext(CurrentSongContext)?.changeInstrumentMode
