import { Grid } from "@mui/material"
import UsersTable from "./components/UsersTable"

const Users = () => {

    return (
        <Grid sx={{ height: "100%" }}>
            <UsersTable />
        </Grid>
    )
}

export default Users
