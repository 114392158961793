import { Box } from "@mui/material"
import { useCurrentSong } from "../../hooks/contexts/currentSongContext"
import { Image } from "./Image/Image"
import logo from "../f_500x500_white_bg.png"
import Text from "./Text"
import { SONG_TYPES } from "../../utils/types"
import { formatString } from "../../utils/formatter"

const NowPlaying = () => {
    const currentSong = useCurrentSong()
    const isKeytone = currentSong?.type === SONG_TYPES.KEY

    return currentSong ? (
        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
            <Image src={currentSong.img ? currentSong.img : logo} description={currentSong.title} sx={{ width: "150px", height: "150px" }} />
            <Box sx={{ paddingY: "1rem" }}>
                <Text bold>{isKeytone ? currentSong.keytone + " " + formatString(currentSong.category) : currentSong.title}</Text>
            </Box>
        </Box>
    ) : null
}
export default NowPlaying
