import { createContext, useState, ReactNode, useContext } from "react"

interface CurrentLocationContextProps {
    currentLocation: string
    changeCurrentLocation: (location: string) => void
}

const CurrentLocationContext = createContext<CurrentLocationContextProps>({
    currentLocation: "",
    changeCurrentLocation: () => {},
})

export const CurrentLocationProvider = ({ children }: { children: ReactNode }) => {
    const [currentLocation, setCurrentLocation] = useState<string>("")

    const changeCurrentLocation = (location: string) => {
        // Set current song
        setCurrentLocation(location)
    }

    return <CurrentLocationContext.Provider value={{ currentLocation, changeCurrentLocation }}>{children}</CurrentLocationContext.Provider>
}

export const useCurrentLocation = () => useContext(CurrentLocationContext).currentLocation
export const useChangeCurrentLocation = () => useContext(CurrentLocationContext)?.changeCurrentLocation
