import { Typography } from "@mui/material"

type TextSize = "tiny" | "small" | "standard" | "heading" | "giant"

const TEXT_SIZES: { [size in TextSize]: number } = {
    tiny: 13,
    small: 14,
    standard: 16,
    heading: 20,
    giant: 32,
}

interface TextProps {
    children: string
    size?: keyof typeof TEXT_SIZES | number
    bold?: boolean
    italic?: boolean
    prettify?: boolean
    displayedLines?: number
    hideOverflow?: boolean
    noWrap?: boolean
    color?: string
    textAlign?: string
}

function prettifyString(input: string): string {
    if (!input) return input
    return input.charAt(0).toUpperCase() + input.slice(1).toLowerCase()
}

const Text = ({ children, size = TEXT_SIZES.standard, bold, italic, prettify, displayedLines, hideOverflow, noWrap, color, textAlign }: TextProps) => {
    const fontSize = typeof size === "number" ? size : TEXT_SIZES[size]
    const textContents = prettify ? prettifyString(children) : children

    return (
        <Typography
            component="div"
            noWrap={(!displayedLines && hideOverflow) || noWrap}
            sx={{
                fontFamily: "Raleway",
                fontSize: `${fontSize}px`,
                fontWeight: bold ? "bold" : "",
                fontStyle: italic ? "italic" : "",
                textOverflow: hideOverflow ? "ellipsis" : "",
                lineHeight: displayedLines ? 1.2 : "",
                overflow: displayedLines ? "hidden" : "",
                display: displayedLines ? "-webkit-box" : "",
                WebkitLineClamp: displayedLines ? displayedLines : "",
                WebkitBoxOrient: displayedLines ? "vertical" : "",
                wordWrap: displayedLines ? "break-word" : "",
                overflowWrap: displayedLines ? "break-word" : "",
                color: color || "black",
                textAlign: textAlign || "left",
            }}
        >
            {textContents}
        </Typography>
    )
}

export default Text
