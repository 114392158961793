import React from "react"
import { Box, ToggleButtonGroup, ToggleButton, Container } from "@mui/material"
import { useIsSmallScreen } from "../hooks/useIsSmallScreen"
// import { usePostMqttChangeSection } from "../mqtt/mqttHandlerContext"
import Text from "../assets/components/Text"

const Section = () => {
    // const postMqttChangeSection = usePostMqttChangeSection()
    const [alignment, setAlignment] = React.useState<string | null>("1")
    const isSmallScreen = useIsSmallScreen()

    const handleAlignment = (event: React.MouseEvent<HTMLElement>, newAlignment: string | null) => {
        setAlignment(newAlignment)
    }

    return (
        <Container>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
                {/* <Text size="heading" bold>
                    Välj del av låten
                </Text>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Box sx={{ width: isSmallScreen ? "100%" : "50%" }}>
                    <ToggleButtonGroup value={alignment} exclusive fullWidth onChange={handleAlignment} aria-label="text alignment">
                        <ToggleButton value="1" aria-label="Section one" onClick={() => postMqttChangeSection("1")}>
                            1
                        </ToggleButton>
                        <ToggleButton value="2" aria-label="Section two" onClick={() => postMqttChangeSection("2")}>
                            2
                        </ToggleButton>
                        <ToggleButton value="3" aria-label="Section three" onClick={() => postMqttChangeSection("3")}>
                            3
                        </ToggleButton>
                        <ToggleButton value="4" aria-label="Section four" onClick={() => postMqttChangeSection("4")}>
                            4
                        </ToggleButton>
                    </ToggleButtonGroup>
                </Box> */}
            </Box>
        </Container>
    )
}

export default Section
