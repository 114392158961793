import { MouseEventHandler } from "react";
import { Button, CircularProgress, ButtonProps } from "@mui/material";
import { SvgIconTypeMap } from "@mui/material";
import { OverridableComponent } from "@mui/types";

interface BasicButtonProps extends ButtonProps {
  icon?: string | OverridableComponent<SvgIconTypeMap<{}, "svg">>;
  progress?: boolean;
}

type ButtonType = "button" | "submit" | "reset" | undefined;

const buttonStyles = {
  fontFamily: "Raleway",
  fontSize: "16px",
}

const BasicButton = ({
  title,
  onClick,
  type,
  disabled,
  icon: Icon,
  progress,
  fullWidth,
  variant,
  ...rest
}: BasicButtonProps) => {
  return (
    <Button
      type={type}
      disabled={disabled}
      onClick={onClick}
      fullWidth={fullWidth}
	  variant={variant}
      sx={buttonStyles}
      {...rest}
    >
      {Icon &&
        (typeof Icon === "string" ? (
          <img
            src={Icon as string}
            alt={title}
            style={{ paddingRight: "5px" }}
          />
        ) : (
          <Icon
            sx={{
              marginRight: "5px",
              stroke: "#ffffff",
              strokeWidth: "0.5px",
              fontSize: "20px",
            }}
          />
        ))}
      {title}
      {progress && (
        <CircularProgress
          size={16}
          color="inherit"
          sx={{ marginLeft: "8px" }}
        />
      )}
    </Button>
  );
};

export default BasicButton;
