import { VolumeDown, VolumeMute, VolumeOff, VolumeUp } from "@mui/icons-material"

export const VolumeIcon = ({ value }: { value: number }) => {
    if (value === 0) {
        return <VolumeOff />
    }
    if (value <= 0.3 && value > 0) {
        return <VolumeMute />
    }
    if (value < 0.3 && value >= 0.7) {
        return <VolumeDown />
    }
    if (value > 0.7) {
        return <VolumeUp />
    } else {
        return <VolumeDown />
    }
}
