import { Box } from "@mui/material"
import { useCurrentUser } from "../../hooks/contexts/currentUserContext"
import TextParagraph from "../../assets/components/TextParagraph"
import RouteButton from "../../assets/components/RouteButton"
import { ROUTES } from "../../utils/api"
import { AddLinkOutlined, ShortcutOutlined } from "@mui/icons-material"
import ChangePassword from "../../components/ChangePassword"
import BasicAccordion from "../../assets/components/BasicAccordion"

const Account = () => {
    const currentUser = useCurrentUser()

    return (
        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start", gap: "20px" }}>
            <TextParagraph heading="Kopplad mailadress" body={currentUser?.auth.email} />

            <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start", gap: "8px" }}>
                <TextParagraph heading="Lösenord" />
                <BasicAccordion summary={<TextParagraph body="Ändra lösenord" />} details={<ChangePassword />} />
            </Box>

            {currentUser?.customer ? (
                <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start", gap: "8px" }}>
                    <TextParagraph heading="Licens som kopplats" />
                    <RouteButton title={currentUser.customer.alias} route={ROUTES.SETTINGS.LICENSE.fullPath} icon={ShortcutOutlined} />
                </Box>
            ) : (
                <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start", gap: "5px" }}>
                    <TextParagraph heading="Licens som kopplats" body="Ditt konto är inte kopplat till en licens ännu" />
                    <RouteButton title="Koppla en licens" route={ROUTES.SETTINGS.LICENSE.fullPath} icon={AddLinkOutlined} />
                </Box>
            )}
        </Box>
    )
}

export default Account
